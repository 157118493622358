import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../context/UserProvider';
import ProtectedAxios from '../api/protectedAxios';
import { toast } from 'react-hot-toast'
import { CiSearch } from 'react-icons/ci'
import CustomModal from '../components/modals/CustomModal';
import { validateBaseEmailTemplateBody } from '../utils/helper';
import Table from 'react-bootstrap/Table';
import LoadingSkeleton from '../components/LoadingSkeleton';
import { RiDeleteBin5Line, RiEdit2Line, RiEye2Line, RiEyeLine } from 'react-icons/ri';
import { Helmet } from 'react-helmet';

const EmailTemplates = () => {
    const [user] = useContext(UserContext);

    const [searchedTerm, setSearchedTerm] = useState("")
    const [searchedText, setSearchedText] = useState("")

    const [baseEmailTemplates, setBaseEmailTemplates] = useState([])
    const [loadingBaseEmailTemplates, setLoadingBaseEmailTemplates] = useState(true)

    const [normalEmailTemplates, setNormalEmailTemplates] = useState([])
    const [loadingNormalEmailTemplates, setLoadingNormalEmailTemplates] = useState(true)


    const [selectedBaseEmailTemplate, setSelectedBaseEmailTemplate] = useState(null)
    const [newBaseEmailTemplateData, setNewBaseEmailTemplateData] = useState({
        email_template_name: "",
        email_body: ""
    })

    const [selectedNormalEmailTemplate, setSelectedNormalEmailTemplate] = useState(null)
    const [newNormalEmailTemplateData, setNewNormalEmailTemplateData] = useState({
        email_template_name: "",
        email_subject: "",
        email_css: "",
        email_body: "",
        base_email_template_id: undefined
    })

    const [addingBaseEmailTemplate, setAddingBaseEmailTemplate] = useState(false)
    const [addingNormalEmailTemplate, setAddingNormalEmailTemplate] = useState(false)

    const [deletingBaseEmailTemplate, setDeletingBaseEmailTemplate] = useState(false)
    const [deletingNormalEmailTemplate, setDeletingNormalEmailTemplate] = useState(false)

    const [updatingBaseEmailTemplate, setUpdatingBaseEmailTempltate] = useState(false)
    const [updatingNormalEmailTemplate, setUpdatingNormalEmailTempltate] = useState(false)

    const [addingBaseEmailTemplateError, setAddingBaseEmailTemplateError] = useState("")
    const [addingNormalEmailTemplateError, setAddingNormalEmailTemplateError] = useState("")

    const [deletingBaseEmailTemplateError, setDeletingBaseEmailTemplateError] = useState("")
    const [deletingNormalEmailTemplateError, setDeletingNormalEmailTemplateError] = useState("")

    const [updatingBaseEmailTemplateError, setUpdatingBaseEmailTemplateError] = useState("")
    const [updatingNormalEmailTemplateError, setUpdatingNormalEmailTemplateError] = useState("")

    const [addBaseEmailTemplateModalState, setAddBaseEmailTemplateModalState] = useState(false)
    const [deleteBaseEmailTemplateModalState, setDeleteBaseEmailTemplateModalState] = useState(false)
    const [updateBaseEmailTemplateModalState, setUpdateBaseEmailTemplateModalState] = useState(false)

    const [addNormalEmailTemplateModalState, setAddNormalEmailTemplateModalState] = useState(false)
    const [deleteNormalEmailTemplateModalState, setDeleteNormalEmailTemplateModalState] = useState(false)
    const [updateNormalEmailTemplateModalState, setUpdateNormalEmailTemplateModalState] = useState(false)

    const [baseTemplatePreviewContent, setBaseTemplatePreviewContent] = useState("")
    const [baseTemplatePreviewModalState, setBaseTemplatePreviewModalState] = useState(false)

    const [normalTemplatePreviewModalState, setNormalTemplatePreviewModalState] = useState(false)

    useEffect(() => {
        fetchBaseEmailTemplates()
        fetchNormalEmailTemplates()
    }, [])

    const fetchBaseEmailTemplates = () => {
        setLoadingBaseEmailTemplates(true)
        ProtectedAxios.get(`/admin/getBaseEmailTemplates?query=${searchedTerm}`)
            .then(res => {
                if (res.data) {
                    setBaseEmailTemplates(res.data)
                }
                setLoadingBaseEmailTemplates(false)
            })
            .catch(err => {
                setLoadingBaseEmailTemplates(false)
                console.log(err);
                if (err.response.data.error) {
                    toast.error(err.response.data.error)
                }
                else {
                    toast.error("Could not get the base email templates at the moment. Please try again later.")
                }
            })
    }

    const fetchNormalEmailTemplates = () => {
        setLoadingNormalEmailTemplates(true)
        ProtectedAxios.get(`/admin/getNormalEmailTemplates?query=${searchedTerm}`)
            .then(res => {
                if (res.data) {
                    setNormalEmailTemplates(res.data)
                    console.log(res.data[0]);
                    
                }
                setLoadingNormalEmailTemplates(false)
            })
            .catch(err => {
                setLoadingNormalEmailTemplates(false)
                console.log(err);
                if (err.response.data.error) {
                    toast.error(err.response.data.error)
                }
                else {
                    toast.error("Could not get the base email templates at the moment. Please try again later.")
                }
            })
    }

    const searchTemplates = (e) => {
        e.preventDefault()
        setSearchedText(searchedTerm)
        fetchBaseEmailTemplates()
        fetchNormalEmailTemplates()
    }

    const addBaseEmailTemplate = () => {
        setAddingBaseEmailTemplateError("")

        const isBodyValid = validateBaseEmailTemplateBody(newBaseEmailTemplateData.email_body)

        if (!isBodyValid.isValid) {
            setAddingBaseEmailTemplateError(isBodyValid.error)
            return
        }

        setAddingBaseEmailTemplate(true)
        ProtectedAxios.post(`/admin/addBaseEmailTemplate`, { ...newBaseEmailTemplateData })
            .then(res => {
                if (res.data) {
                    setBaseEmailTemplates(prev => {
                        let updated = [...prev]
                        updated.unshift(res.data)
                        return updated
                    })

                    setNewBaseEmailTemplateData({
                        email_template_name: "",
                        email_body: ""
                    })

                    toast.success("Email template added")
                }
                setAddingBaseEmailTemplate(false)
                setAddBaseEmailTemplateModalState(false)
            })
            .catch(err => {
                if (err.response.data.error) {
                    setAddingBaseEmailTemplateError(err.response.data.error)
                } else {
                    console.log(err)
                }
                setAddingBaseEmailTemplate(false)
            })
    }

    const updateBaseEmailTemplate = () => {
        setUpdatingBaseEmailTemplateError("")

        const isBodyValid = validateBaseEmailTemplateBody(selectedBaseEmailTemplate?.email_body)

        if (!isBodyValid.isValid) {
            setUpdatingBaseEmailTemplateError(isBodyValid.error)
            return
        }

        setUpdatingBaseEmailTempltate(true)
        ProtectedAxios.post(`/admin/updateBaseEmailTemplate`, { ...selectedBaseEmailTemplate })
            .then(res => {
                if (res.data) {
                    setBaseEmailTemplates(prev => {
                        const selectedItemIndex = prev.findIndex(template => template.email_template_id === selectedBaseEmailTemplate?.email_template_id)
                        let updated = [...prev]
                        updated[selectedItemIndex] = res.data
                        return updated
                    })
                    toast.success("Email template updated")
                }
                setUpdatingBaseEmailTempltate(false)
                setUpdateBaseEmailTemplateModalState(false)
            })
            .catch(err => {
                if (err.response.data.error) {
                    setUpdatingBaseEmailTemplateError(err.response.data.error)
                } else {
                    console.log(err)
                }
                setUpdatingBaseEmailTempltate(false)
            })
    }

    const addNormalEmailTemplate = () => {
        setAddingNormalEmailTemplateError("")

        setAddingNormalEmailTemplate(true)
        ProtectedAxios.post(`/admin/addNormalEmailTemplate`, { ...newNormalEmailTemplateData })
            .then(res => {
                if (res.data) {
                    setNormalEmailTemplates(prev => {
                        let updated = [...prev]
                        updated.unshift(res.data)
                        return updated
                    })

                    setNewNormalEmailTemplateData({
                        email_template_name: "",
                        email_subject: "",
                        email_css: "",
                        email_body: "",
                        base_email_template_id: undefined
                    })

                    toast.success("Email template added")
                }
                setAddingNormalEmailTemplate(false)
                setAddNormalEmailTemplateModalState(false)
            })
            .catch(err => {
                if (err.response.data.error) {
                    setAddingNormalEmailTemplateError(err.response.data.error)
                } else {
                    console.log(err)
                }
                setAddingNormalEmailTemplate(false)
            })
    }

    const updateNormalEmailTemplate = () => {
        setUpdatingNormalEmailTemplateError("")

        setUpdatingNormalEmailTempltate(true)
        ProtectedAxios.post(`/admin/updateNormalEmailTemplate`, { ...selectedNormalEmailTemplate })
            .then(res => {
                if (res.data) {
                    setNormalEmailTemplates(prev => {
                        const selectedItemIndex = prev.findIndex(template => template.email_template_id === selectedNormalEmailTemplate?.email_template_id)
                        let updated = [...prev]
                        updated[selectedItemIndex] = res.data
                        return updated
                    })
                    toast.success("Email template updated")
                }
                setUpdatingNormalEmailTempltate(false)
                setUpdateNormalEmailTemplateModalState(false)
            })
            .catch(err => {
                if (err.response.data.error) {
                    setUpdatingNormalEmailTemplateError(err.response.data.error)
                } else {
                    console.log(err)
                }
                setUpdatingNormalEmailTempltate(false)
            })
    }

    const deleteBaseEmailTemplate = (email_template_id) => {
        if (email_template_id) {
            setDeletingBaseEmailTemplateError("")
            setDeletingBaseEmailTemplate(true)
            ProtectedAxios.delete(`/admin/baseEmailTemplate/${email_template_id}`)
                .then(res => {
                    if (res.data) {
                        let filteredEmailTemplates = [...baseEmailTemplates]
                        filteredEmailTemplates = filteredEmailTemplates.filter(tempalte => tempalte.email_template_id !== email_template_id)
                        setBaseEmailTemplates(filteredEmailTemplates)
                        toast.success("Email template deleted")
                        setDeleteBaseEmailTemplateModalState(false)
                    }
                    setDeletingBaseEmailTemplate(false)
                })
                .catch(err => {
                    if (err.response.data.error) {
                        setDeletingBaseEmailTemplateError(err.response.data.error)
                    } else {
                        console.log(err)
                    }
                    setDeletingBaseEmailTemplate(false)
                })
        }
    }

    const deleteNormalEmailTemplate = (email_template_id) => {
        if (email_template_id) {
            setDeletingNormalEmailTemplateError("")
            setDeletingNormalEmailTemplate(true)
            ProtectedAxios.delete(`/admin/normalEmailTemplate/${email_template_id}`)
                .then(res => {
                    if (res.data) {
                        let filteredEmailTemplates = [...normalEmailTemplates]
                        filteredEmailTemplates = filteredEmailTemplates.filter(tempalte => tempalte.email_template_id === email_template_id)
                        setNormalEmailTemplates(filteredEmailTemplates)
                        toast.success("Email template deleted")
                    }
                    setDeletingNormalEmailTemplate(false)
                })
                .catch(err => {
                    if (err.response.data.error) {
                        setDeletingNormalEmailTemplateError(err.response.data.error)
                    } else {
                        console.log(err)
                    }
                    setDeletingNormalEmailTemplate(false)
                })
        }
    }


    const formatBaseEmailHtmlForPreview = (html) => {
        const additionalContentPlaceholder = '{{additionalContent}}';
        const additionalContentReplacement = '<div id="additional-content" class="font-us border py-3 px-4 rounded-2" style="width:fit-content; background-color:#f3d6ff; padding: 12px 16px; font-size: 13px; border-radius: 6px">Your additional content will be seen here</div>';

        if (html.includes(additionalContentPlaceholder)) {
            return html.replace(additionalContentPlaceholder, additionalContentReplacement);
        } else {
            return html;
        }
    }

    const formatNormalEmailTemplateHtmlForPreview = (baseHtml, additionalStyles, additionalContent) => {
        if (!baseHtml) {
            return ""
        }

        let formattedHtml = baseHtml;

        // Replace {{additionalStyles}} with the provided styles
        formattedHtml = formattedHtml.replace('{{additionalStyles}}', additionalStyles);

        // Replace {{additionalContent}} with the provided content
        formattedHtml = formattedHtml.replace('{{additionalContent}}', additionalContent);

        return formattedHtml;
    }



    return (
        <div className='container py-5'>
            <Helmet>
                <title>Email Templates - SageCollab</title>
            </Helmet>

            <div className='d-flex flex-column gap-3'>
                <h2>Email Templates</h2>
                <div className='d-flex gap-2 justify-content-end w-100'>
                    <button className='button button-ghost' onClick={() => setAddBaseEmailTemplateModalState(true)}>New Base Template</button>
                    <button className='button' onClick={() => setAddNormalEmailTemplateModalState(true)}>New Template</button>
                </div>
            </div>

            <div className='mt-3'>
                <form onSubmit={searchTemplates} className='form md p-0 m-0'><input type="text" value={searchedTerm} onChange={e => setSearchedTerm(e.target.value)} placeholder="search templates" /></form>
                <p className='text-secondary my-4'>{searchedText && `showing search results for '${searchedText}'`}</p>
                <div className='mt-5'>
                    <div className='custom-divider'><p className='px-3 py-2 rounded-2 text-black fw-semibold'>Base&nbsp;Templates</p> <span /></div>

                    <div className='table-container'>
                        <table className='api-keys-table'>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Body</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    loadingBaseEmailTemplates
                                        ?
                                        [1, 2].map(i => (
                                            <tr key={i}>
                                                {
                                                    [1, 2, 3, 4].map(j => (
                                                        <td key={j} className='py-3' style={{ paddingRight: "10px" }}><LoadingSkeleton type="td" /></td>
                                                    ))
                                                }
                                            </tr>
                                        ))

                                        :
                                        baseEmailTemplates.length === 0
                                            ?
                                            <tr>
                                                <td className='pt-3' colSpan={6}>
                                                    <p className='font-weight-semibold m-0'>No base email templates found</p>
                                                </td>
                                            </tr>

                                            :
                                            baseEmailTemplates.map((template, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{i + 1}</td>
                                                        <td>{template.email_template_name}</td>
                                                        <td style={{ maxWidth: "15rem" }}>{template.email_body.substring(0, 150)}{template.email_body.length > 150 && "....."}</td>
                                                        <td>
                                                            <button className='edit-btn' id='preview-base-email-template-button' title='Preview template' onClick={e => { setBaseTemplatePreviewContent(template.email_body); setBaseTemplatePreviewModalState(true) }}><RiEyeLine className='edit-icon' /></button>
                                                            <button className='edit-btn' id='update-base-email-template-button' title='Update template' onClick={e => { setSelectedBaseEmailTemplate(template); setUpdateBaseEmailTemplateModalState(true) }}><RiEdit2Line className='edit-icon' /></button>
                                                            <button className='edit-btn' id='delete-base-email-template-button' title='Delete template' onClick={e => { setSelectedBaseEmailTemplate(template); setDeleteBaseEmailTemplateModalState(true) }}><RiDeleteBin5Line className='edit-icon' /></button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                }

                            </tbody>
                        </table>
                    </div>
                </div>



                <div className='mt-5 pt-5'>
                    <div className='custom-divider'><p className='px-3 py-2 rounded-2 text-black fw-semibold'>Email&nbsp;Templates</p> <span /></div>

                    <div className='table-container'>
                        <table className='api-keys-table'>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Body</th>
                                    <th>Base Template</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    loadingNormalEmailTemplates
                                        ?
                                        [1, 2].map(i => (
                                            <tr key={i}>
                                                {
                                                    [1, 2, 3, 4].map(j => (
                                                        <td key={j} className='py-3' style={{ paddingRight: "10px" }}><LoadingSkeleton type="td" /></td>
                                                    ))
                                                }
                                            </tr>
                                        ))

                                        :
                                        normalEmailTemplates.length === 0
                                            ?
                                            <tr>
                                                <td className='pt-3' colSpan={6}>
                                                    <p className='font-weight-semibold m-0'>No email templates found</p>
                                                </td>
                                            </tr>

                                            :
                                            normalEmailTemplates.map((template, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>{i + 1}</td>
                                                        <td>{template.email_template_name}</td>
                                                        <td style={{ maxWidth: "15rem" }}>{template.email_body.substring(0, 150)}{template.email_body.length > 150 && "....."}</td>
                                                        <td>{template.base_email_template_ref.email_template_name}</td>
                                                        <td>
                                                            <button className='edit-btn' id='preview-normal-email-template-button' title='Preview template' onClick={e => { setSelectedNormalEmailTemplate(template); setNormalTemplatePreviewModalState(true) }}><RiEyeLine className='edit-icon' /></button>
                                                            <button className='edit-btn' id='update-normal-email-template-button' title='Update template' onClick={e => { setSelectedNormalEmailTemplate(template); setUpdateNormalEmailTemplateModalState(true) }}><RiEdit2Line className='edit-icon' /></button>
                                                            <button className='edit-btn' id='delete-base-email-template-button' title='Delete template' onClick={e => { setSelectedNormalEmailTemplate(template); setDeleteNormalEmailTemplateModalState(true) }}><RiDeleteBin5Line className='edit-icon' /></button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                }

                            </tbody>
                        </table>
                    </div>
                </div>

            </div>


            {/* ADD BASE EMAIL TEMPLATE */}
            <CustomModal
                size="xl"
                show={addBaseEmailTemplateModalState}
                onHide={() => setAddBaseEmailTemplateModalState(prev => !prev)}
                title="Add base email template"
                content={
                    <div className='py-2'>
                        <div className='input-grp my-2'>
                            <label htmlFor="new-base-template-name">Email Template Name <span className='text-danger'>*</span></label>
                            <input required type="text" id="new-base-template-name" value={newBaseEmailTemplateData.email_template_name} onChange={e => { setAddingBaseEmailTemplateError(""); setNewBaseEmailTemplateData(prev => { return { ...prev, email_template_name: e.target.value } }) }} />
                        </div>

                        <div className='input-grp my-2'>
                            <label htmlFor="new-base-template-body">Email Template Body <span className='text-danger'>*</span></label>
                            <textarea rows={10} className='font-xxs' required id="new-base-template-body" value={newBaseEmailTemplateData.email_body} onChange={e => { setAddingBaseEmailTemplateError(""); setNewBaseEmailTemplateData(prev => { return { ...prev, email_body: e.target.value } }) }} />
                            <p className='text-secondary font-xxs mt-3'><span className='text-danger'>*</span> use <span className='fw-semibold'>{'{{additionalStyles}}'}</span> in the style tag and <span className='fw-semibold'>{'{{additionalContent}}'}</span> in the body tag so that styles and additional content can be added using this base email template.</p>
                        </div>

                        {addingBaseEmailTemplateError.length > 0
                            &&
                            <p className='text-danger'>Error: {addingBaseEmailTemplateError}</p>
                        }

                    </div>
                }

                buttons={
                    <div>
                        <button
                            className='button'
                            disabled={addingBaseEmailTemplate}
                            onClick={addBaseEmailTemplate}
                        >
                            {addingBaseEmailTemplate
                                ? <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="sr-only"></span>
                                </div>

                                : "Create"
                            }
                        </button>
                    </div>
                }
            />



            {/* UPDATE BASE EMAIL TEMPLATE */}
            <CustomModal
                size="xl"
                show={updateBaseEmailTemplateModalState}
                onHide={() => setUpdateBaseEmailTemplateModalState(prev => !prev)}
                title="Update base email template"
                content={
                    <div className='py-2'>
                        <div className='input-grp my-2'>
                            <label htmlFor="new-base-template-name">Email Template Name <span className='text-danger'>*</span></label>
                            <input required type="text" id="new-base-template-name" value={selectedBaseEmailTemplate?.email_template_name} onChange={e => { setUpdatingBaseEmailTemplateError(""); setSelectedBaseEmailTemplate(prev => { return { ...prev, email_template_name: e.target.value } }) }} />
                        </div>

                        <div className='input-grp my-2'>
                            <label htmlFor="new-base-template-body">Email Template Body <span className='text-danger'>*</span></label>
                            <textarea rows={10} className='font-xxs' required id="new-base-template-body" value={selectedBaseEmailTemplate?.email_body} onChange={e => { setUpdatingBaseEmailTemplateError(""); setSelectedBaseEmailTemplate(prev => { return { ...prev, email_body: e.target.value } }) }} />
                            <p className='text-secondary font-xxs mt-3'><span className='text-danger'>*</span> use <span className='fw-semibold'>{'{{additionalStyles}}'}</span> in the style tag and <span className='fw-semibold'>{'{{additionalContent}}'}</span> in the body tag so that styles and additional content can be added using this base email template.</p>
                        </div>

                        {updatingBaseEmailTemplateError.length > 0
                            &&
                            <p className='text-danger'>Error: {updatingBaseEmailTemplateError}</p>
                        }

                    </div>
                }

                buttons={
                    <div>
                        <button
                            className='button'
                            disabled={updatingBaseEmailTemplate}
                            onClick={updateBaseEmailTemplate}
                        >
                            {updatingBaseEmailTemplate
                                ? <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="sr-only"></span>
                                </div>

                                : "Update"
                            }
                        </button>
                    </div>
                }
            />



            {/* ADD EMAIL TEMPLATE */}
            <CustomModal
                size="xl"
                show={addNormalEmailTemplateModalState}
                onHide={() => setAddNormalEmailTemplateModalState(prev => !prev)}
                title="Add email template"
                content={
                    <div className='py-2'>
                        <div className='d-flex flex-column flex-sm-row gap-0 gap-sm-4'>
                            <div className='input-grp my-4'>
                                <label htmlFor="new-template-name">Email Template Name <span className='text-danger'>*</span></label>
                                <input required type="text" id="new-template-name" value={newNormalEmailTemplateData.email_template_name} onChange={e => { setAddingNormalEmailTemplateError(""); setNewNormalEmailTemplateData(prev => { return { ...prev, email_template_name: e.target.value } }) }} />
                            </div>

                            <div className='input-grp my-4'>
                                <label htmlFor="new-template-base">Base Template <span className='text-danger'>*</span></label>
                                <select
                                    className='form-select'
                                    id="new-template-base"
                                    value={newNormalEmailTemplateData.base_email_template_id}
                                    onChange={e => setNewNormalEmailTemplateData(prev => { return { ...prev, base_email_template_id: parseInt(e.target.value) } })}
                                >
                                    <option value={undefined} selected></option>
                                    {baseEmailTemplates.map((template, i) => (
                                        <option key={i} value={template.email_template_id}>{template.email_template_name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className='input-grp my-4'>
                            <label htmlFor="new-template-subject">Email Subject <span className='text-danger'>*</span></label>
                            <input required type="text" id="new-template-subject" value={newNormalEmailTemplateData.email_subject} onChange={e => { setAddingNormalEmailTemplateError(""); setNewNormalEmailTemplateData(prev => { return { ...prev, email_subject: e.target.value } }) }} />
                            <p className='mt-1 font-xxs text-secondary'>&nbsp;&nbsp;• Use {'{{'}<span className='font-xxs'>variable_name</span>{'}}'} to add placeholders that can be later replaced with dynamic values</p>
                        </div>

                        <div className='input-grp my-4'>
                            <label htmlFor="new-template-css">Email Template CSS <span className='text-danger'>*</span></label>
                            <textarea rows={4} className='font-xxs' required id="new-template-css" value={newNormalEmailTemplateData.email_css} onChange={e => { setAddingNormalEmailTemplateError(""); setNewNormalEmailTemplateData(prev => { return { ...prev, email_css: e.target.value } }) }} />
                            <p className='mt-1 font-xxs text-secondary mb-1'>&nbsp;&nbsp;• Do not wrap this content with style or any tag as that will be added from base template</p>
                        </div>

                        <div className='input-grp my-4'>
                            <label htmlFor="new-template-body">Email Template Body <span className='text-danger'>*</span></label>
                            <textarea rows={10} className='font-xxs' required id="new-template-body" value={newNormalEmailTemplateData.email_body} onChange={e => { setAddingNormalEmailTemplateError(""); setNewNormalEmailTemplateData(prev => { return { ...prev, email_body: e.target.value } }) }} />
                            <p className='mt-1 font-xxs text-secondary mb-1'>&nbsp;&nbsp;• Do not wrap this content with html or body tag as that will be added from base template</p>
                            <p className='mt-1 font-xxs text-secondary'>&nbsp;&nbsp;• Use {'{{'}<span className='font-xxs'>variable_name</span>{'}}'} to add placeholders that can be later replaced with dynamic values</p>
                        </div>

                        {addingNormalEmailTemplateError.length > 0
                            &&
                            <p className='text-danger'>Error: {addingNormalEmailTemplateError}</p>
                        }

                    </div>
                }

                buttons={
                    <div>
                        <button
                            className='button'
                            disabled={addingNormalEmailTemplate}
                            onClick={addNormalEmailTemplate}
                        >
                            {addingNormalEmailTemplate
                                ? <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="sr-only"></span>
                                </div>

                                : "Create"
                            }
                        </button>
                    </div>
                }
            />



            {/* UPDATE EMAIL TEMPLATE */}
            <CustomModal
                size="xl"
                show={updateNormalEmailTemplateModalState}
                onHide={() => setUpdateNormalEmailTemplateModalState(prev => !prev)}
                title="Update email template"
                content={
                    <div className='py-2'>
                        <div className='d-flex flex-column flex-sm-row gap-0 gap-sm-4'>
                            <div className='input-grp my-4'>
                                <label htmlFor="new-template-name">Email Template Name <span className='text-danger'>*</span></label>
                                <input required type="text" id="new-template-name" value={selectedNormalEmailTemplate?.email_template_name} onChange={e => { setUpdatingNormalEmailTemplateError(""); setSelectedNormalEmailTemplate(prev => { return { ...prev, email_template_name: e.target.value } }) }} />
                            </div>

                            <div className='input-grp my-4'>
                                <label htmlFor="new-template-base">Base Template <span className='text-danger'>*</span></label>
                                <select
                                    className='form-select'
                                    id="new-template-base"
                                    value={selectedNormalEmailTemplate?.base_email_template_id}
                                    onChange={e => setSelectedNormalEmailTemplate(prev => { return { ...prev, base_email_template_id: parseInt(e.target.value) } })}
                                >
                                    {baseEmailTemplates.map((template, i) => (
                                        <option key={i} value={template.email_template_id}>{template.email_template_name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className='input-grp my-4'>
                            <label htmlFor="new-template-subject">Email Subject <span className='text-danger'>*</span></label>
                            <input required type="text" id="new-template-subject" value={selectedNormalEmailTemplate?.email_subject} onChange={e => { setUpdatingNormalEmailTemplateError(""); setSelectedNormalEmailTemplate(prev => { return { ...prev, email_subject: e.target.value } }) }} />
                            <p className='mt-1 font-xxs text-secondary'>&nbsp;&nbsp;• Use {'{{'}<span className='font-xxs'>variable_name</span>{'}}'} to add placeholders that can be later replaced with dynamic values</p>
                        </div>

                        <div className='input-grp my-4'>
                            <label htmlFor="new-template-css">Email Template CSS <span className='text-danger'>*</span></label>
                            <textarea rows={4} className='font-xxs' required id="new-template-css" value={selectedNormalEmailTemplate?.email_css} onChange={e => { setUpdatingNormalEmailTemplateError(""); setSelectedNormalEmailTemplate(prev => { return { ...prev, email_css: e.target.value } }) }} />
                            <p className='mt-1 font-xxs text-secondary mb-1'>&nbsp;&nbsp;• Do not wrap this content with style or any tag as that will be added from base template</p>
                        </div>

                        <div className='input-grp my-4'>
                            <label htmlFor="new-template-body">Email Template Body <span className='text-danger'>*</span></label>
                            <textarea rows={10} className='font-xxs' required id="new-template-body" value={selectedNormalEmailTemplate?.email_body} onChange={e => { setUpdatingNormalEmailTemplateError(""); setSelectedNormalEmailTemplate(prev => { return { ...prev, email_body: e.target.value } }) }} />
                            <p className='mt-1 font-xxs text-secondary mb-1'>&nbsp;&nbsp;• Do not wrap this content with html or body tag as that will be added from base template</p>
                            <p className='mt-1 font-xxs text-secondary'>&nbsp;&nbsp;• Use {'{{'}<span className='font-xxs'>variable_name</span>{'}}'} to add placeholders that can be later replaced with dynamic values</p>
                        </div>

                        {updatingNormalEmailTemplateError.length > 0
                            &&
                            <p className='text-danger'>Error: {updatingNormalEmailTemplateError}</p>
                        }

                    </div>
                }

                buttons={
                    <div>
                        <button
                            className='button'
                            disabled={updatingNormalEmailTemplate}
                            onClick={updateNormalEmailTemplate}
                        >
                            {updatingNormalEmailTemplate
                                ? <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="sr-only"></span>
                                </div>

                                : "Update"
                            }
                        </button>
                    </div>
                }
            />



            {/* BASE EMAIL TEMPLATE PREVIEW */}
            <CustomModal
                size='xl'
                show={baseTemplatePreviewModalState}
                onHide={() => setBaseTemplatePreviewModalState(prev => !prev)}
                title="Base email template preview"
                showFooter={false}
                content={
                    <iframe srcDoc={formatBaseEmailHtmlForPreview(baseTemplatePreviewContent)} style={{ width: '100%', height: '75vh' }} />
                }
            />



            {/* NORMAL EMAIL TEMPLATE PREVIEW */}
            <CustomModal
                size='xl'
                show={normalTemplatePreviewModalState}
                onHide={() => setNormalTemplatePreviewModalState(prev => !prev)}
                title="Email preview"
                showFooter={false}
                content={
                    <iframe srcDoc={formatNormalEmailTemplateHtmlForPreview(selectedNormalEmailTemplate?.base_email_template_ref?.email_body, selectedNormalEmailTemplate?.email_css, selectedNormalEmailTemplate?.email_body)} style={{ width: '100%', height: '75vh' }} />
                }
            />


            {/* DELETE BASE EMAIL TEMPLATE */}
            <CustomModal
                show={deleteBaseEmailTemplateModalState}
                onHide={() => { setDeletingBaseEmailTemplateError(""); setDeleteBaseEmailTemplateModalState(prev => !prev) }}
                title="Delete base email template"
                content={
                    <div className='my-5'>
                        <h3 className='font-xs'>Are you sure you want to delete this base email template?</h3>
                        <p>{selectedBaseEmailTemplate?.email_template_name}</p>
                        {deletingBaseEmailTemplateError.length > 0
                            &&
                            <p className='text-danger'>Error: {deletingBaseEmailTemplateError}</p>
                        }
                    </div>
                }
                buttons={
                    <div>
                        <button
                            className='button button-danger'
                            disabled={deletingBaseEmailTemplate}
                            onClick={() => deleteBaseEmailTemplate(selectedBaseEmailTemplate?.email_template_id)}
                        >
                            {deletingBaseEmailTemplate
                                ? <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="sr-only"></span>
                                </div>

                                : "Yes, delete"
                            }
                        </button>
                    </div>
                }
            />


            {/* DELETE NORMAL EMAIL TEMPLATE */}
            <CustomModal
                show={deleteNormalEmailTemplateModalState}
                onHide={() => setDeleteNormalEmailTemplateModalState(prev => !prev)}
                title="Delete email template"
                content={
                    <div className='my-5'>
                        <h3 className='font-xs'>Are you sure you want to delete this email template?</h3>
                        <p>{selectedNormalEmailTemplate?.email_template_name}</p>
                        {deletingNormalEmailTemplateError.length > 0
                            &&
                            <p className='text-danger'>Error: {deletingNormalEmailTemplateError}</p>
                        }
                    </div>
                }
                buttons={
                    <div>
                        <button
                            className='button button-danger'
                            disabled={deletingNormalEmailTemplate}
                            onClick={() => deleteNormalEmailTemplate(selectedNormalEmailTemplate?.email_template_id)}
                        >
                            {deletingNormalEmailTemplate
                                ? <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="sr-only"></span>
                                </div>

                                : "Yes, delete"
                            }
                        </button>
                    </div>
                }
            />


        </div>
    );
};

export default EmailTemplates;
