import React, { useContext, useEffect, useRef, useState } from 'react'
import ProtectedAxios from '../api/protectedAxios';
import { RiCheckLine, RiCloseLine, RiDeleteBin5Line, RiMailAddFill, RiMailFill } from 'react-icons/ri'
import { GiArmorUpgrade, GiTeamUpgrade } from 'react-icons/gi'
import { toast } from 'react-hot-toast';
import Modal from 'react-bootstrap/Modal';
import { MdGroupAdd } from 'react-icons/md'
import { UserContext } from '../context/UserProvider';
import { AiFillAlert, AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import DeleteUserButton from './DeleteUserButton';
import MyDropdown from './MyDropdown';
import ManageAccessModal from './ManageAccessModal';
import AddGeminiAPIkeyModal from './AddGeminiAPIkeyModal'
import AddOpenaiAPIkeyModal from './AddOpenaiAPIkeyModal';
import AddAzureOpenaiKeysModal from './AddAzureOpenaiKeysModal';
import ManageEnterpriseChildAccounts from '../pages/ManageEnterpriseChildAccounts';
import MyTemplates from '../pages/MyTemplates';
import { FiAlertTriangle } from 'react-icons/fi';


const ManageUserTable_Actions = ({ loading = true, userDetails, fetchAllUsers, fetchUserSubscriptionDetails }) => {
    const [user] = useContext(UserContext)
    const [user_details, set_user_details] = useState(userDetails)

    const [selectedUser, setSelectedUser] = useState(null)
    const [deletingUser, setDeletingUser] = useState(false)
    const [addingFreeEnterpriseSubscription, setAddingFreeEnterpriseSubscription] = useState(false)

    const [showOpenaiApiKeyModal, setShowOpenaiApiKeyModal] = useState(false);
    const [showGeminiApiKeyModal, setShowGeminiApiKeyModal] = useState(false);
    const [showAzureKeysModal, setShowAzureKeysModal] = useState(false);

    const [upgradeEndDate, setUpgradeEndDate] = useState('')
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [upgradingUser, setUpgradingUser] = useState(false)


    const [confirmFreeEnterpriseModal, setConfirmFreeEnterpriseModal] = useState(false)
    const closeConfirmFreeEnterpriseModal = () => { setConfirmFreeEnterpriseModal(false) }
    const showConfirmFreeEnterpriseModal = () => { setConfirmFreeEnterpriseModal(true) }
    const [togglingUserStatus, setTogglingUserStatus] = useState(false)


    const [manageChildAccountsModal, setManageChildAccountModal] = useState(false)
    const [manageTemplatesModal, setManageTemplatesModal] = useState(false)


    const disableUser = (_userId) => {
        setTogglingUserStatus(true)
        ProtectedAxios.post('/admin/disableUser', { selected_user_id: _userId })
            .then(res => {
                set_user_details({ ...user_details, is_active: 0 })
                setTogglingUserStatus(false)
            })
            .catch(err => {
                setTogglingUserStatus(false)
                toast.error(err.response.data.error)
            })
    }

    const activateUser = (_userId) => {
        setTogglingUserStatus(true)
        ProtectedAxios.post('/admin/activateUser', { selected_user_id: _userId })
            .then(res => {
                set_user_details({ ...user_details, is_active: 1 })
                setTogglingUserStatus(false)
            })
            .catch(err => {
                setTogglingUserStatus(false)
                toast.error(JSON.stringify(err.response.data.error))
            })
    }

    const upgradePlan = e => {
        e.preventDefault()
        setUpgradingUser(true)
        const dateObj = new Date(upgradeEndDate)
        const currentDate = new Date()
        dateObj.setHours(currentDate.getHours())
        dateObj.setMinutes(currentDate.getMinutes())
        dateObj.setSeconds(currentDate.getSeconds())
        // alert(dateObj)
        ProtectedAxios.post('/admin/upgradeUserToStandardPlan', { selected_user_id: selectedUser.user_id, email: selectedUser.email, name: selectedUser.name, selected_customer_id: selectedUser.stripe_customer_id, upgradeEndDate })
            .then(res => {
                if (res.data) {
                    setUpgradingUser(false)
                    handleClose()
                    fetchUserSubscriptionDetails()
                    toast.success(`${selectedUser.name} successfully upgraded to Standard plan`)
                }
            })
            .catch(err => {
                setUpgradingUser(false)
                console.log(err);
                if (err.response.status === 500) {
                    toast.error(err.response.data.error)
                }
            })
    }

    const addFreeEnterpriseSubscription = async (_user_id, _username, _customer_id) => {
        setAddingFreeEnterpriseSubscription(true)
        toast.loading(`adding Enterprise subscription for user - ${selectedUser.username}`, { id: 'loading' })
        ProtectedAxios.post('/admin/addFreeEnterpriseSubscription', { user_id: user.user_id, selected_user_id: selectedUser.user_id, customer_id: selectedUser.stripe_customer_id })
            .then(res => {
                if (res.data) {
                    setAddingFreeEnterpriseSubscription(false)
                    toast.success(`Enterprise subscription added for user - '${selectedUser.username}'`, { id: 'loading' })
                    closeConfirmFreeEnterpriseModal(``)
                    fetchAllUsers()
                }
            })
            .catch(err => {
                setAddingFreeEnterpriseSubscription(false)
                console.log(err);
                toast.error("could not add enterprise subscription at the moment, please try again later.", { id: 'loading' })
            })
    }



    return (
        <>
            {loading
                ?
                <td style={{ minWidth: '6rem', height: '3rem' }}>
                    <div style={{ width: '100%', height: '100%' }} className='d-flex justify-content-center align-items-center'>
                        <div className="spinner-border spinner-border-sm" role="status">
                            <span className="sr-only"></span>
                        </div>
                    </div>
                </td>
                :
                <>
                    <td>{user_details.is_active ? 'Active' : 'Disabled'}</td>
                    <td>{user_details.is_official_template_creator ? 'Yes' : 'No'}</td>

                    <td className=''>
                        <div className='d-flex gap-2'>
                            <button className={`edit-btn position-relative`} onClick={() => setShowOpenaiApiKeyModal(true)}>
                                {!user_details?.openai_api_key
                                    ?
                                    <RiCloseLine className='w-xxs h-xxs bg-danger text-white rounded-circle position-absolute top-2 right-2' style={{ right: "2px", top: "5px" }} />
                                    :
                                    <RiCheckLine className='w-xxs h-xxs bg-success text-white rounded-circle position-absolute top-2 right-2' style={{ right: "2px", top: "5px" }} />
                                }
                                <img className='w-s h-s' src="https://www.svgrepo.com/show/306500/openai.svg" />
                            </button>

                            <button className={`edit-btn position-relative`} onClick={() => setShowGeminiApiKeyModal(true)}>
                                {!user_details?.gemini_api_key
                                    ?
                                    <RiCloseLine className='w-xxs h-xxs bg-danger text-white rounded-circle position-absolute top-2 right-2' style={{ right: "2px", top: "5px" }} />
                                    :
                                    <RiCheckLine className='w-xxs h-xxs bg-success text-white rounded-circle position-absolute top-2 right-2' style={{ right: "2px", top: "5px" }} />
                                }
                                <img className='w-s h-s' src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/google-gemini-icon.png" />
                            </button>

                            <button className={`edit-btn position-relative`} onClick={() => setShowAzureKeysModal(true)}>
                                {(!user_details?.azure_openai_endpoint || !user_details?.azure_openai_api_key || !user_details?.azure_deployment_id)
                                    ?
                                    <RiCloseLine className='w-xxs h-xxs bg-danger text-white rounded-circle position-absolute top-2 right-2' style={{ right: "2px", top: "5px" }} />
                                    :
                                    <RiCheckLine className='w-xxs h-xxs bg-success text-white rounded-circle position-absolute top-2 right-2' style={{ right: "2px", top: "5px" }} />
                                }
                                <img className='w-s h-s' src="https://swimburger.net/media/eyzjad3q/azure.png?width=250&height=250&v=1d7697083360c50" />
                            </button>
                        </div>
                    </td>

                    <td>
                        <div className='d-flex px-2'>
                            <MyDropdown>
                                <div
                                    className='dropdown-item d-flex align-items-center gap-2'
                                    disabled={deletingUser || addingFreeEnterpriseSubscription}
                                    value={user_details.user_id}
                                    onClick={() => {
                                        if (user_details.is_active) {
                                            disableUser(user_details.user_id)
                                        }
                                        else {
                                            activateUser(user_details.user_id)
                                        }
                                    }}
                                >
                                    {
                                        user_details.is_active
                                            ?
                                            <div title='disable account'>Disable Account</div>

                                            :
                                            <div title='activate account'>Enable Account</div>
                                    }
                                    {togglingUserStatus
                                        &&
                                        <div>
                                            <div className="spinner-border spinner-border-sm" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                        </div>
                                    }
                                </div>

                                <div className='dropdown-item'>
                                    <DeleteUserButton showAsIcon={false} selectedUser={user_details} fetchAllUsers={fetchAllUsers} />
                                </div>

                                {user_details.plan_id === process.env.REACT_APP_PRICE_A_ID
                                    &&
                                    <div
                                        className='dropdown-item'
                                        disabled={deletingUser || addingFreeEnterpriseSubscription}
                                        title='Upgrade to Standard plan'
                                        onClick={() => {
                                            setSelectedUser(user_details);
                                            handleShow()
                                        }}
                                    >
                                        Add Standard Plan
                                    </div>
                                }


                                {(user_details.plan_id === process.env.REACT_APP_PRICE_A_ID || user_details.plan_id === process.env.REACT_APP_PRICE_B_ID)
                                    &&
                                    <div
                                        className='dropdown-item'
                                        disabled={deletingUser || addingFreeEnterpriseSubscription}
                                        title='Add Enterprise Subscription'
                                        onClick={() => {
                                            setSelectedUser(user_details);
                                            showConfirmFreeEnterpriseModal()
                                        }}
                                    >
                                        Add Enterprise Plan
                                    </div>
                                }


                                <div
                                    className='dropdown-item'
                                    title='Manage Access'
                                    onClick={() => {
                                        setSelectedUser(user_details)
                                    }}
                                >
                                    <ManageAccessModal selectedUser={selectedUser} setSelectedUser={setSelectedUser} setUserDetails={set_user_details} />
                                </div>


                                {user_details.plan_id === process.env.REACT_APP_PRICE_C_ID
                                    &&
                                    <div
                                        className='dropdown-item'
                                        title='Child Accounts'
                                        onClick={() => {
                                            setManageChildAccountModal(true)
                                        }}
                                    >
                                        Child Accounts
                                    </div>
                                }

                                <div
                                    className='dropdown-item'
                                    title='Templates'
                                    onClick={() => {
                                        setManageTemplatesModal(true)
                                    }}
                                >
                                    Templates
                                </div>


                                <div
                                    className='dropdown-item'
                                    title='Refresh Details'
                                    onClick={() => {
                                        fetchUserSubscriptionDetails()
                                    }}
                                >
                                    Refresh Details
                                </div>

                            </MyDropdown>

                        </div>
                    </td>
                </>
            }

            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Upgrade to Standard Plan</Modal.Title>
                </Modal.Header>
                <form onSubmit={upgradePlan}>
                    <Modal.Body>
                        <div className='upgrade-confirmation-body px-3 py-3'>
                            <h5>{selectedUser?.name}</h5>
                            <p>Current Plan - <b>{selectedUser?.plan}</b></p>
                            <br />
                            <div className='input-grp'>
                                <label>Upgrade End Date <span className='text-danger'>*</span></label>
                                <input required type="datetime-local"
                                    min={new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().slice(0, 16)}
                                    value={upgradeEndDate} onChange={e => setUpgradeEndDate(e.target.value)} />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='button' disabled={upgradingUser}>
                            {upgradingUser
                                ?
                                <>
                                    Upgrade to Standard
                                    <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                        <span className="sr-only"></span>
                                    </div>
                                </>

                                : "Upgrade to Standard"
                            }
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>


            {/* confirm modal */}
            <Modal show={confirmFreeEnterpriseModal} onHide={closeConfirmFreeEnterpriseModal} centered size="md">
                <Modal.Header closeButton>
                    <Modal.Title>Confirm</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <p>Are you sure you want to add Enterprise subscription for user - {selectedUser?.username}</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type='button' onClick={closeConfirmFreeEnterpriseModal} className='button button-danger'>Close</button>
                    <button type="submit" className='button' onClick={() => addFreeEnterpriseSubscription()} disabled={addingFreeEnterpriseSubscription}>
                        {addingFreeEnterpriseSubscription
                            ?
                            <>
                                Confirm
                                <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            </>

                            : <>Confirm</>
                        }
                    </button>
                </Modal.Footer>
            </Modal>


            <AddGeminiAPIkeyModal
                show={showGeminiApiKeyModal}
                onHide={() => setShowGeminiApiKeyModal(false)}
                heading='Add/Edit API Key'
                apiKey={user_details?.gemini_api_key}
                selectedUser={user_details}
                setSelectedUser={set_user_details}
                title={<>Add/Edit Gemini API key for user: <br /><span className='fw-medium font-s'>{user_details.name} ({user_details?.username})</span></>}
            />
            <AddOpenaiAPIkeyModal
                show={showOpenaiApiKeyModal}
                onHide={() => setShowOpenaiApiKeyModal(false)}
                heading='Add/Edit API Key'
                apiKey={user_details?.openai_api_key}
                selectedUser={user_details}
                setSelectedUser={set_user_details}
                title={<>Add/Edit OpenAI API key for user: <br /><span className='fw-medium font-s'>{user_details.name} ({user_details?.username})</span></>}
            />
            <AddAzureOpenaiKeysModal
                show={showAzureKeysModal}
                onHide={() => setShowAzureKeysModal(false)}
                apiKey={user_details?.azure_openai_api_key}
                endpoint={user_details?.azure_openai_endpoint}
                deploymentId={user_details?.azure_deployment_id}
                model={user_details?.azure_openai_model}
                selectedUser={user_details}
                setSelectedUser={set_user_details}
                heading="Manage Azure Keys"
                title={<>Add/Edit Azure OpenAI keys for user: <br /><span className='fw-medium font-s'>{user_details.name} ({user_details?.username})</span></>}
            />


            {/* Manage Child Accounts Modal */}
            <Modal show={manageChildAccountsModal} onHide={() => setManageChildAccountModal(false)} centered size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Manage Child Accounts</Modal.Title>
                </Modal.Header>
                <Modal.Body className='pt-0'>
                    <div className='position-relative'>
                        <div className='shared-chat-band position-static m-0 font-xxs'>Managing for - {user_details.name} ({user_details.username})</div>
                        <ManageEnterpriseChildAccounts selectedUser={user_details} setSelectedUser={set_user_details} showTitle={false} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type='button' onClick={() => setManageChildAccountModal(false)} className='button button-danger'>Close</button>
                </Modal.Footer>
            </Modal>


            {/* Manage Templates Modal */}
            <Modal show={manageTemplatesModal} onHide={() => setManageTemplatesModal(false)} centered size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Manage Templates</Modal.Title>
                </Modal.Header>
                <Modal.Body className='pt-0'>
                    <div className='position-relative'>
                        <div className='shared-chat-band position-static m-0 font-xxs gap-2'>
                            <p>Managing for - {user_details.name} ({user_details.username})</p>
                            <span>|</span>
                            <span className='text-secondary font-us'>Any changes made will reflect in {user_details.username}'s account</span>
                        </div>
                        <MyTemplates selectedUser={user_details} setSelectedUser={set_user_details} />
                    </div>
                </Modal.Body>
                {/* <Modal.Footer>
                    <button type='button' onClick={() => setManageTemplatesModal(false)} className='button button-danger'>Close</button>
                </Modal.Footer> */}
            </Modal>

        </>
    )
}

export default ManageUserTable_Actions