import React from 'react'

const TemplateMarketplaceIconPlusAlt = (props) => (
    <svg
        width={12}
        height={12}
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#clip0_195_17)">
            <rect y={2.00002} width={4.6} height={6} rx={0.5}
                style={{ fill: "var(--color-template-icon-1)" }} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 4.0859C9.95282 4.08923 9.90518 4.09093 9.85715 4.09093C8.75258 4.09093 7.85715 3.19549 7.85715 2.09093C7.85715 2.06045 7.85783 2.03014 7.85918 2H5.9C5.62386 2 5.4 2.22386 5.4 2.5V5.5C5.4 5.77614 5.62386 6 5.9 6H9.5C9.77614 6 10 5.77614 10 5.5V4.0859Z"
                style={{ fill: "var(--color-template-icon-2)" }}
            />
            <rect
                x={5.4}
                y={7.00002}
                width={4.6}
                height={5}
                rx={0.5}
                style={{ fill: "var(--color-template-icon-3)" }}
            />
            <rect y={9.00002} width={4.6} height={3} rx={0.5} fill="#039A85"
                style={{ fill: "var(--color-template-icon-4)" }}
            />
            <path
                d="M8.81818 2.01624H10.0682M10.0682 2.01624H11.3182M10.0682 2.01624V0.766235M10.0682 2.01624V3.26624"
                stroke="black"
                strokeWidth={0.6}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="clip0_195_17">
                <rect width={12} height={12} fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export default TemplateMarketplaceIconPlusAlt