import React from 'react'

const TemplateMarketplaceIconAlt = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={14}
        height={14}
        viewBox="0 0 14 14"
        fill="none"
        {...props}
    >
        <path
            d="M0 0.84C0 0.376081 0.376081 0 0.84 0H5.6C6.06392 0 6.44 0.376081 6.44 0.84V7.56C6.44 8.02392 6.06392 8.4 5.6 8.4H0.84C0.376081 8.4 0 8.02392 0 7.56V0.84Z"
            fill="#00DABC"
            style={{ fill: "var(--color-template-icon-1)" }}
        />
        <path
            d="M7.56 0.84C7.56 0.376081 7.93608 0 8.4 0H13.16C13.6239 0 14 0.376081 14 0.84V4.76C14 5.22392 13.6239 5.6 13.16 5.6H8.4C7.93608 5.6 7.56 5.22392 7.56 4.76V0.84Z"
            fill="#0DC8AE"
            style={{ fill: "var(--color-template-icon-2)" }}
        />
        <path
            d="M7.56 7.84C7.56 7.37608 7.93608 7 8.4 7H13.16C13.6239 7 14 7.37608 14 7.84V13.16C14 13.6239 13.6239 14 13.16 14H8.4C7.93608 14 7.56 13.6239 7.56 13.16V7.84Z"
            fill="#86E8DA"
            style={{ fill: "var(--color-template-icon-3)" }}
        />
        <path
            d="M0 10.64C0 10.1761 0.376081 9.8 0.84 9.8H5.6C6.06392 9.8 6.44 10.1761 6.44 10.64V13.16C6.44 13.6239 6.06392 14 5.6 14H0.84C0.376081 14 0 13.6239 0 13.16V10.64Z"
            fill="#039A85"
            style={{ fill: "var(--color-template-icon-4)" }}
        />
    </svg>
)

export default TemplateMarketplaceIconAlt