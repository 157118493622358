import React, { useContext, useEffect, useRef, useState } from 'react'
import ProtectedAxios from '../api/protectedAxios'
import { UserContext } from '../context/UserProvider'
import placeholderProfile from "../assets/placeholder-profile.jpg"
import { calculateDateDistance, formatDate } from '../utils/helper'
import Responses from './Responses'
import { SocketContext } from '../context/SocketProvider'
import { HiOutlineTemplate } from 'react-icons/hi'
import { FaExternalLinkAlt } from "react-icons/fa";
import { PiCaretDown } from "react-icons/pi";
import { NavLink } from 'react-router-dom';
import TemplateMarketplaceIconAlt from './custom-svgs/templateMarketplaceIconAlt'
import MessageNotes from './MessageNotes'

const Messages = ({ chat_id, chatDetails, chatDetailsSetter, messages, setMessages, isEnterpriseAdmin, pageEnded, setPageEnded, viewingSharedChat = false, tokens, updateTokens, setShowAdModal, setShowMaxInteractionsReachedModal }) => {
    const [user] = useContext(UserContext)
    const [mySocket] = useContext(SocketContext)

    const [loading, setLoading] = useState(false)
    const [loadingMore, setLoadingMore] = useState(false)
    const [templateDetailsDropdownState, setTemplateDetailsDropdownState] = useState(false);
    const templateDropdownRef = useRef();
    const loadMoreDivRef = useRef(null)

    useEffect(() => {
        if (mySocket) {
            mySocket.on('message_added', (data) => {
                if (data.created_by !== user.user_id && pageEnded) {
                    setMessages(prevMessages => [...prevMessages, data.new_message])
                }
            })
        }
    }, [pageEnded])

    useEffect(() => {
        fetchMessages()
    }, [])

    const fetchMessages = (_load = true) => {
        if (_load) {
            setLoading(true)
        }
        setPageEnded(false)
        ProtectedAxios.post("/users/messages", { chat_id, viewingSharedChat, page: 1 })
            .then(res => {
                setMessages(res.data)
                setLoading(false)
            })
            .catch(err => {
                console.log("err in fetching messages - ", err);
                setLoading(false)
            })
    }

    let myPage = 1
    const loadMoreMessages = () => {
        setLoadingMore(true)
        myPage++
        ProtectedAxios.post("/users/messages", { chat_id, viewingSharedChat, page: myPage })
            .then(res => {
                if (res.data.length < 4 || res.data.length === 0) {
                    setPageEnded(true)
                }
                setMessages(prev => [...prev, ...res.data])
                // let createdAt = new Date().toISOString()
                // setMessages(prev => [...prev, { chat_id, created_by: user.user_id, profile_picture_src: user.profile_picture_src, message_content: "This is a temp test message from Arib", username: user.username, name: user.name, created_at: createdAt }])

                setLoadingMore(false)
            })
            .catch(err => {
                console.log("err in fetching messages - ", err);
                setLoadingMore(false)
            })
    }


    const handleIntersection = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                loadMoreMessages()
            }
        });
    };

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1,
        };
        const observer = new IntersectionObserver(handleIntersection, options);
        const loadMoreButton = loadMoreDivRef.current;

        if (loadMoreButton) {
            observer.observe(loadMoreButton);
        }

        return () => {
            if (loadMoreButton) {
                observer.unobserve(loadMoreButton);
            }
        };
    }, [loading]);

    useClickOutside(templateDropdownRef)
    function useClickOutside(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setTemplateDetailsDropdownState(false)
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    return (
        <div className='message-container container'>
            {loading
                ?
                <div className='d-flex justify-content-center align-items-center' style={{ minHeight: '50vh' }}>
                    <div className="spinner-border spinner-border" role="status">
                        <span className="sr-only"></span>
                    </div>
                </div>

                :
                <>
                    <div className='chat-info'>
                        <span>
                            {chatDetails.created_by === user.user_id ? "You" : chatDetails.name} started this chat
                        </span>
                    </div>

                    <div className='messages'>
                        {messages.map((message, i) => {
                            return (
                                <div className={`message ${message.created_by === user.user_id ? "self-message" : ""}`} key={i} id={`message-${message.created_at}`}>
                                    <div className='message-header'>
                                        {message.created_by !== user.user_id
                                            &&
                                            <div>
                                                <div className='profile-pic chat-profile-pic' style={{ backgroundImage: `url(${message.profile_picture_src ? `${message.profile_picture_src}` : placeholderProfile})` }} />
                                            </div>
                                        }
                                        <div className='message-body'>
                                            <div className='message-content'>
                                                {message.username !== user.username
                                                    &&
                                                    <span className='message-by'>{message.name}</span>
                                                }
                                                <p className='m-0 py-2 pt-3'>
                                                    {
                                                        chatDetails?.template_ref?.template_id && i === 0
                                                            ? chatDetails?.template_ref?.type === "CHAT_STARTER_PRIVATE"
                                                                ?
                                                                <div ref={templateDropdownRef} className="chat-template-detail bg-glass rounded mt-3">
                                                                    <div className="d-flex align-items-center justify-content-between  px-3 gap-1 py-2 bg-glass-lightened rounded">
                                                                        <div className='d-flex align-items-center gap-2'>
                                                                            <div className=''>
                                                                                <TemplateMarketplaceIconAlt className="rounded" style={{ width: "var(--size-l)", height: "var(--size-l)", backgroundColor: "var(--color-primary)", padding: "3px" }} />
                                                                            </div>
                                                                            <span className="font-xxs fw-medium color-dark me-4">Started with template</span>
                                                                        </div>
                                                                        <button
                                                                            className="edit-btn"
                                                                            onClick={() => setTemplateDetailsDropdownState((prev) => !prev)}
                                                                        >
                                                                            <PiCaretDown
                                                                                className={`edit-icon transition-all ${templateDetailsDropdownState ? "rotate-180" : "rotate-0"
                                                                                    }`}
                                                                            />
                                                                        </button>
                                                                    </div>
                                                                    <div
                                                                        className={`template-content-container transition-height ${templateDetailsDropdownState ? "open" : "closed"
                                                                            }`}
                                                                    >
                                                                        <div className="px-3 pt-3 pb-4" id="template-content">
                                                                            <div className="d-flex align-items-start justify-content-between gap-2 mb-2">
                                                                                <span className="font-xxs fw-medium m-0 color-dark opacity-75">
                                                                                    {chatDetails.template_ref.name}
                                                                                </span>
                                                                                {(chatDetails.template_ref.is_public || chatDetails.template_ref.user_ref.role_ref.role === "admin")
                                                                                    &&
                                                                                    <NavLink to={`/marketplace/template/${chatDetails.template_ref.template_id}`} target="_blank" rel="noopener noreferrer">
                                                                                        <FaExternalLinkAlt className="font-xxs" />
                                                                                    </NavLink>
                                                                                }
                                                                            </div>
                                                                            <p className="font-us color-dark-lightened opacity-75 m-0">
                                                                                {chatDetails.template_ref.description}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className='d-flex flex-column gap-3 align-items-start'>
                                                                    {message.message_content}
                                                                    <div ref={templateDropdownRef} className="chat-template-detail bg-glass rounded mt-3">
                                                                        <div className="d-flex align-items-center justify-content-between  px-3 gap-1 py-2 bg-glass-lightened rounded">
                                                                            <div className='d-flex align-items-center gap-2'>
                                                                                <div className=''>
                                                                                    <TemplateMarketplaceIconAlt className="rounded" style={{ width: "var(--size-l)", height: "var(--size-l)", backgroundColor: "var(--color-primary)", padding: "3px" }} />
                                                                                </div>
                                                                                <span className="font-xxs fw-medium color-dark me-4">Started with template</span>
                                                                            </div>
                                                                            <button
                                                                                className="edit-btn"
                                                                                onClick={() => setTemplateDetailsDropdownState((prev) => !prev)}
                                                                            >
                                                                                <PiCaretDown
                                                                                    className={`edit-icon transition-all ${templateDetailsDropdownState ? "rotate-180" : "rotate-0"
                                                                                        }`}
                                                                                />
                                                                            </button>
                                                                        </div>
                                                                        <div
                                                                            className={`template-content-container transition-height ${templateDetailsDropdownState ? "open" : "closed"
                                                                                }`}
                                                                        >
                                                                            <div className="px-3 pt-3 pb-4" id="template-content">
                                                                                <div className="d-flex align-items-start justify-content-between gap-2 mb-2">
                                                                                    <span className="font-xxs fw-medium m-0 color-dark opacity-75">
                                                                                        {chatDetails.template_ref.name}
                                                                                    </span>
                                                                                    {(chatDetails.template_ref.is_public || chatDetails.template_ref.user_ref.role_ref.role === "admin")
                                                                                        &&
                                                                                        <NavLink to={`/marketplace/template/${chatDetails.template_ref.template_id}`} target="_blank" rel="noopener noreferrer">
                                                                                            <FaExternalLinkAlt className="font-xxs" />
                                                                                        </NavLink>
                                                                                    }
                                                                                </div>
                                                                                <p className="font-us color-dark-lightened opacity-75 m-0">
                                                                                    {chatDetails.template_ref.description}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            : message.message_content
                                                    }
                                                </p>

                                                <MessageNotes chat_id={chat_id} message_id={message.message_id} isEnterpriseAdmin={isEnterpriseAdmin} />
                                            </div>
                                            <span className={`message-at container ${message.created_by === user.user_id ? "text-end" : ""}`}>{calculateDateDistance(new Date(message.created_at))}</span>
                                        </div>
                                    </div>
                                    {message.message_id
                                        &&
                                        <Responses
                                            chat_id={chat_id}
                                            chatDetails={chatDetails}
                                            chatDetailsSetter={chatDetailsSetter}
                                            message_id={message.message_id}
                                            isEnterpriseAdmin={isEnterpriseAdmin}
                                            tokens={tokens}
                                            updateTokens={updateTokens}
                                            setMessages={setMessages}
                                            setShowAdModal={setShowAdModal}
                                            setShowMaxInteractionsReachedModal={setShowMaxInteractionsReachedModal}
                                            lastResponse={pageEnded && i + 1 === messages.length}
                                        />
                                    }
                                </div>
                            )
                        })}
                        {!pageEnded
                            ?
                            loadingMore
                                ?
                                <div className='d-flex justify-content-center my-4 py-4'>
                                    <div className="spinner-border spinner-border-md" role="status">
                                        <span className="sr-only"></span>
                                    </div>
                                </div>
                                :
                                <div ref={loadMoreDivRef}></div>
                            : ""
                        }


                    </div>
                </>
            }

        </div>
    )
}

export default Messages