import React, { useContext, useEffect, useRef, useState } from 'react'
import ProtectedAxios from '../api/protectedAxios'
import { UserContext } from '../context/UserProvider'
import { toast } from 'react-hot-toast'
import Modal from 'react-bootstrap/Modal';
import { formatDate } from '../utils/helper';
import { AiFillEdit, AiOutlineArrowRight, AiOutlineEdit, AiOutlineEye } from 'react-icons/ai';
import { RiChatUploadFill, RiChatUploadLine, RiCloseLine, RiDeleteBin4Line, RiDeleteBin5Line, RiDownloadLine, RiFileExcel2Line, RiLockLine, RiTextWrap } from 'react-icons/ri';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { BsArrowLeftShort, BsArrowRightShort, BsChat, BsFillInfoCircleFill, BsTextareaT } from 'react-icons/bs'
import { MdArrowBack, MdCancel, MdClose, MdDelete, MdDownload, MdNotificationsActive, MdNotificationsOff, MdOutlineCancel, MdOutlineChat, MdOutlineMinimize, MdOutlineTextFields, MdPublic, MdSearch, MdTextFields, MdTextRotationNone, MdWorkspacePremium } from 'react-icons/md';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import useSubscriptionDetails from '../hooks/useSubscriptionDetails';
import LoadingSkeleton from '../components/LoadingSkeleton';
import { GrFormAdd, GrFormClose } from 'react-icons/gr';
import { BiFilter, BiCategoryAlt, BiRefresh } from 'react-icons/bi';
import { GoMultiSelect } from 'react-icons/go'
import { PiCrownSimpleFill, PiNavigationArrowFill, PiTextAa, PiTextAaLight } from 'react-icons/pi'
import { LiaHandRock } from 'react-icons/lia'
import { TbClick, TbWorldShare } from 'react-icons/tb'
import { DragDropContainer, DropTarget } from 'react-drag-drop-container';
import { TbDragDrop2 } from "react-icons/tb";
import { MdDragIndicator } from "react-icons/md";
import { RiGitRepositoryPrivateLine, RiGlobalLine } from "react-icons/ri";
import { VscGistSecret } from "react-icons/vsc";
import { HiDuplicate, HiOutlineTemplate } from 'react-icons/hi';
import { FaCrown, FaRegFileExcel, FaRegFilePdf } from 'react-icons/fa';
import MyDropdown from '../components/MyDropdown';
import logo from '../assets/logo.svg'
import ShareTemplateDialog from '../components/ShareTemplateDialog';
import ShareTemplateAccess from '../components/ShareTemplateAccess';
import { Helmet } from 'react-helmet';
import PublicTemplateSearchEngineVisibilityDialog from '../components/PublicTemplateSearchEngineVisibilityDialog';

const MyTemplates = ({ selectedUser, setSelectedUser }) => {
  const [user, setUser] = useContext(UserContext)
  const [subscriptionDetail] = useSubscriptionDetails()

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const navigate = useNavigate()

  const [loadingTemplates, setLoadingTemplates] = useState(true)
  const [templates, setTemplates] = useState([])

  const [templateSearchText, setTemplateSearchText] = useState('')
  const [searchingWithText, setSearchingWithText] = useState(false)
  const [searchText, setSearchText] = useState(false)


  const [templateData, setTemplateData] = useState({
    name: '',
    description: '',
    prompt: '',
    selectedCategories: [],
    selectedCompany: null,
    showing_additional_options: false,
    isSharingWithChildAccounts: true,
    isPublic: false,
    isOfficial: true,
    notify_creator_on_use: false,
    type: '',
    typeText: ''
  })
  const [addingTemplate, setAddingTemplate] = useState(false)
  const [showingPreviewTextOnButton, setShowingPreviewTextOnButton] = useState(false)
  const [companyNotSelected, setCompanyNotSelected] = useState(false)

  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [deletingTemplate, setDeletingTemplate] = useState(false)

  // State to control the visibility of the template modal
  const [show, setShow] = useState(false);

  // Update `show` based on the `createTemplate=true` URL param whenever the URL changes
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const createTemplateUrlParam = searchParams.get('createTemplate') === 'true';
    setShow(createTemplateUrlParam);
  }, [location.search]); // Re-run this effect when the URL search params change

  // Handle closing the template and updating the URL
  const handleClose = async () => {
    setShow(false);

    const searchParams = new URLSearchParams(location.search);
    // Remove the 'createTemplate' param
    searchParams.delete('createTemplate');

    // Build the new URL path with the updated search params
    const newUrl = `${location.pathname}?${searchParams.toString()}`;

    // Update the URL without reloading the page
    navigate(newUrl, { replace: true });
  };


  const [editModal, setEditModal] = useState(false)
  const closeEditModal = async () => { setCompanyNotSelected(false); setEditModal(false) }
  const showEditModal = () => {
    setTemplateEditorStep(0)
    setEditModal(true);
  }

  useEffect(() => {
    setInterval(() => {
      setShowingPreviewTextOnButton(prev => !prev)
    }, 6000)
  }, [])

  useEffect(() => {
    setTemplateBuilderError("")
  }, [show, editModal])

  const [confirmResetModal, setConfirmResetModal] = useState(false)
  const closeConfirmResetModal = () => { setConfirmResetModal(false) }
  const openConfirmResetModal = () => { closeEditModal(); setConfirmResetModal(true) }

  const [categoryDropdownVisible, setCategoryDropdownVisible] = useState(false)


  const [templateBuilderStep, setTemplateBuilderStep] = useState(0)
  const [templateEditorStep, setTemplateEditorStep] = useState(0)

  const [templateBuilderError, setTemplateBuilderError] = useState("")

  const [newTemplateStructure, setNewTemplateStructure] = useState([])

  const [loadingSelectedTemplateStructure, setLoadingSelectedTemplateStructure] = useState(false)
  const [selectedTemplateStructure, setSelectedTemplateStructure] = useState([])

  const [verifyingTemplateStep, setVerifyingTemplateStep] = useState(false)



  const handleNewTemplateItemSort = (e) => {
    const dragItemIndex = e.dragData.index

    const targetElementId = e.target.querySelector(".template-element").getAttribute("id")
    const dragOverItemIndex = parseInt(targetElementId.split("-")[targetElementId.split("-").length - 1])

    // duplicate structure
    let new_structure = [...newTemplateStructure]

    //remove and save the dragged item content
    const draggedItemContent = new_structure.splice(dragItemIndex, 1)[0]

    //switch the position
    new_structure.splice(dragOverItemIndex, 0, draggedItemContent)

    //update the actual state
    setNewTemplateStructure(new_structure)

    e.target.scrollIntoView()
  }

  const handleSelectedTemplateItemSort = (e) => {
    const dragItemIndex = e.dragData.index

    const targetElementId = e.target.querySelector(".template-element").getAttribute("id")
    const dragOverItemIndex = parseInt(targetElementId.split("-")[targetElementId.split("-").length - 1])

    // duplicate structure
    let new_structure = [...selectedTemplateStructure]

    //remove and save the dragged item content
    const draggedItemContent = new_structure.splice(dragItemIndex, 1)[0]

    //switch the position
    new_structure.splice(dragOverItemIndex, 0, draggedItemContent)

    //update the actual state
    setSelectedTemplateStructure(new_structure)

    e.target.scrollIntoView()
  }


  const [viewTemplateModal, setViewTemplateModal] = useState(false)
  const closeViewTemplateModal = () => { setViewTemplateModal(false) }
  const openViewTemplateModal = () => { setViewTemplateModal(true) }

  const [deleteTemplateConfirmationModal, setDeleteTemplateConfirmationModal] = useState(false)

  useEffect(() => {
    fetchTemplates()
  }, [])

  useEffect(() => {
    if (templateSearchText.length === 0) {
      fetchTemplates()
    }
  }, [templateSearchText])

  const fetchTemplates = async (e, _selectedCategories, _filterType) => {
    if (e) {
      e?.preventDefault()
    }

    setCategoryDropdownVisible(false)
    setLoadingTemplates(true)

    let selected_categories = _selectedCategories ? _selectedCategories : selectedFilterCategories
    if (templateSearchText.length > 0) {
      setSearchingWithText(true)
      setSearchText(templateSearchText)
    } else {
      setSearchingWithText(false)
    }

    ProtectedAxios.post('/users/fetchMyTemplates', { user_id: user.user_id, selected_user_id: selectedUser?.user_id, templateSearchText: templateSearchText, selectedCategories: selected_categories })
      .then(res => {
        if (res.data) {
          setTemplates(res.data)

          ProtectedAxios.post('/users/getUserTemplateCreatorStatus', { user_id: user.user_id, selected_user_id: selectedUser?.user_id })
            .then(res => {
              if (selectedUser && setSelectedUser) {
                setSelectedUser({ ...selectedUser, is_official_template_creator: res.data.is_official_template_creator })
              } else {
                setUser({ ...user, is_official_template_creator: res.data.is_official_template_creator })
              }

              setLoadingTemplates(false)
            })
        }
      })
      .catch(err => {
        console.log(err);
        toast.error("could not fetch your templates at the moment, please try again later.")
        setLoadingTemplates(false)
      })
  }


  const verifyTemplateBuilderStep = (_step, structure, structureSetter, stepSetter) => {
    // setVerifyingTemplateStep(true)

    if (_step === 0) {
      if (structure.length === 0) {
        setTemplateBuilderError("Add at least one or more element(s) to continue")
        setVerifyingTemplateStep(false)
        return
      }

      let errorAdded = false
      let errorRaisingElementIndex

      structure.forEach((element, i) => {
        if (!errorAdded) {
          if (element.type === "text") {
            if (element.content.length === 0) {
              structureSetter(prev => {
                let updatedData = [...prev]
                updatedData[i].error = "Please add content for the text"
                return updatedData
              })

              document.getElementById(`element-item-${element.id}`).scrollIntoView({ behavior: "smooth" })
              errorAdded = true
              errorRaisingElementIndex = i
            }
          }

          else if (element.type === "hidden-text") {
            if (element.content.length === 0) {
              structureSetter(prev => {
                let updatedData = [...prev]
                updatedData[i].error = "Please add content for the hidden text"
                return updatedData
              })

              document.getElementById(`element-item-${element.id}`).scrollIntoView({ behavior: "smooth" })
              errorAdded = true
              errorRaisingElementIndex = i
            }
          }

          else if (element.type === "textarea") {
            if (element.label.length === 0) {
              structureSetter(prev => {
                let updatedData = [...prev]
                updatedData[i].error = "Please add label for the textarea"
                return updatedData
              })

              document.getElementById(`element-item-${element.id}`).scrollIntoView({ behavior: "smooth" })
              errorAdded = true
              errorRaisingElementIndex = i
            }
          }

          else if (element.type === "select") {
            if (element.label.length === 0) {
              structureSetter(prev => {
                let updatedData = [...prev]
                updatedData[i].error = "Please add label for the select"
                return updatedData
              })

              document.getElementById(`element-item-${element.id}`).scrollIntoView({ behavior: "smooth" })
              errorAdded = true
              errorRaisingElementIndex = i
            }

            else if (element.options.length === 0) {
              structureSetter(prev => {
                let updatedData = [...prev]
                updatedData[i].error = "Please add atleast one option"
                return updatedData
              })

              document.getElementById(`element-item-${element.id}`).scrollIntoView({ behavior: "smooth" })
              errorAdded = true
              errorRaisingElementIndex = i
            }

            else if (element.label.length === 0 && element.options.length === 0) {
              structureSetter(prev => {
                let updatedData = [...prev]
                updatedData[i].error = "Please add a label and atleast one option"
                return updatedData
              })

              document.getElementById(`element-item-${element.id}`).scrollIntoView({ behavior: "smooth" })
              errorAdded = true
              errorRaisingElementIndex = i
            }

            else {
              structureSetter(prev => {
                let updatedData = [...prev]
                updatedData[i].value = updatedData[i].options[0].value
                return updatedData
              })
            }

          }


          else if (element.type === "pdf-context") {
            if (!element?.content && !element?.filename) {
              if (!element?.files) {
                structureSetter(prev => {
                  let updatedData = [...prev]
                  updatedData[i].error = "Please select a file"
                  return updatedData
                })

                document.getElementById(`element-item-${element.id}`).scrollIntoView({ behavior: "smooth" })
                errorAdded = true
                errorRaisingElementIndex = i
              }
            }
          }


          else if (element.type === "csv-context") {
            if (!element?.content && !element?.filename) {
              if (!element?.files) {
                structureSetter(prev => {
                  let updatedData = [...prev]
                  updatedData[i].error = "Please select a file"
                  return updatedData
                })

                document.getElementById(`element-item-${element.id}`).scrollIntoView({ behavior: "smooth" })
                errorAdded = true
                errorRaisingElementIndex = i
              }
            }
          }


        }
      })


      if (!errorAdded) {
        if (selectedTemplate?.selectedCompany) {
          setSelectedTemplate({ ...selectedTemplate, isOfficial: true })
        } else {
          setSelectedTemplate({ ...selectedTemplate, isOfficial: false })
        }

        stepSetter(1)
      }
      else {
        if (errorRaisingElementIndex) {
          document.getElementById(`element-item-${errorRaisingElementIndex}`).scrollIntoView()
        }
      }
    }

    setVerifyingTemplateStep(false)
  }

  const addElement = (dragData, structure, structureSetter) => {
    setTemplateBuilderError("")

    const newId = `${dragData.type}-${new Date().getTime()}${structure.length}`
    const newElement = {
      id: newId,
      type: dragData.type,
      displayName: dragData.displayName,
      category: dragData.category,
      premium: dragData.premium,
      can_be_private: dragData.can_be_private,
      private: dragData.private,
      error: ""
    }

    if (dragData.type === "text") {
      newElement.content = ""
    }
    else if (dragData.type === "hidden-text") {
      newElement.content = ""
    }
    else if (dragData.type === "textarea") {
      newElement.label = ""
    }
    else if (dragData.type === "select") {
      newElement.label = ""
      newElement.options = []
    }
    else if (dragData.type === "plain-text-context") {
      newElement.content = ""
    }

    let insertedElementIndex = 0
    structureSetter(prev => {
      let updatedData = [...prev]

      updatedData.push(newElement)
      insertedElementIndex = updatedData.length - 1

      return updatedData
    })

    setTimeout(() => {
      document.getElementById(`element-item-${insertedElementIndex}`)?.scrollIntoView({ behavior: "smooth" })
    }, 100)
  }

  const deleteElement = (_element, structure, structureSetter) => {
    let index = structure.findIndex(element => element.id === _element.id)

    structureSetter(prev => {
      let updatedElement = prev.filter(element => element.id !== _element.id)
      return updatedElement
    })
  }

  const setTemplateStructure = async (template_id, functionToExecute) => {
    setLoadingSelectedTemplateStructure(true)
    toast.loading("Please wait", { id: "load-prompt", duration: Infinity })
    ProtectedAxios.get(`/users/getTemplatePrompt/${template_id}`)
      .then(res => {
        setLoadingSelectedTemplateStructure(false)
        setSelectedTemplateStructure(res.data.prompt)
        toast.dismiss("load-prompt")
        functionToExecute()
      })
      .catch(err => {
        console.log(err);
        setLoadingSelectedTemplateStructure(false)
        toast.dismiss("load-prompt")

        if (err.response.data.error) {
          return toast.error(err.response.data.error)
        }

        toast.error("Could not get your template prompt! Please try again later.")
      })
  }

  const addTemplate = async (e) => {
    e.preventDefault()

    //check if company is selected
    setCompanyNotSelected(false)

    if (selectedUser) {
      if ((selectedUser.role_id === 1 || templateData.isOfficial) && (selectedUser.is_official_template_creator || selectedUser.role_id === 1) && templateData.selectedCompany === null) {
        setCompanyNotSelected(true)
        return
      }
    }
    else {
      if ((user.role_id === 1 || templateData.isOfficial) && (user.is_official_template_creator || user.role_id === 1) && templateData.selectedCompany === null) {
        setCompanyNotSelected(true)
        return
      }
    }

    if (templateData.name.length > 200) {
      toast.error("name must be below 200 characters")
      return
    }
    if (templateData.description.length > 500) {
      toast.error("description must be below 500 characters")
      return
    }

    setAddingTemplate(true)
    const regex = /{{\s*([^{}]+)\s*}}/g;
    // let prompt = templateData.prompt
    // prompt = prompt.replace(regex, (match, placeholder) => `{{${placeholder.trim()}}}`);

    let prompt = JSON.stringify(newTemplateStructure)
    let isEnterpriseAdmin =
      (selectedUser?.plan_id ? selectedUser.plan_id === process.env.REACT_APP_PRICE_C_ID : subscriptionDetail?.price_id === process.env.REACT_APP_PRICE_C_ID)
        ? true
        : false


    // using formData since want to add files too
    const formData = new FormData()
    formData.append('user_id', user.user_id)
    formData.append('selected_user_id', selectedUser?.user_id)
    formData.append('selected_stripe_customer_id', selectedUser?.stripe_customer_id)
    formData.append('selected_role_id', selectedUser?.role_id)
    formData.append('isEnterpriseAdmin', isEnterpriseAdmin)
    formData.append('isSharingWithChildAccounts', templateData.isSharingWithChildAccounts)
    formData.append('prompt', prompt)
    formData.append('name', templateData.name)
    formData.append('description', templateData.description)
    formData.append('showingAdditionalOptions', templateData.showing_additional_options)
    formData.append('selectedCategories', JSON.stringify(templateData.selectedCategories))
    formData.append('selectedCompany', JSON.stringify(templateData.selectedCompany))
    formData.append('isPublic', templateData.isPublic)
    formData.append('notify_creator_on_use', templateData.notify_creator_on_use)
    formData.append('type', templateData.type)

    newTemplateStructure.forEach((element, index) => {
      if ((element.type === 'pdf-context' || element.type === 'csv-context') && element.files) {
        formData.append(`files-${element.id}`, element.files)
      }
    })

    ProtectedAxios.post('/users/addTemplate', formData)
      .then(res => {
        if (res.data) {
          setTemplateSearchText("")
          // fetchTemplates()

          setTemplates(prev => {
            let updatedTemplates = [...prev];
            const currentTimestamp = new Date().toISOString();
            let newTemplate = {
              ...templateData,
              template_id: res.data.new_template_id,
              prompt: res.data.updated_prompt,
              updated_at: currentTimestamp,
              selectedCompany: templateData.selectedCompany,
              company_url: templateData?.selectedCompany?.company_url,
              company_name: templateData?.selectedCompany?.company_name,
              selectedCategories: templateData.selectedCategories.sort((a, b) => {
                return a.category.localeCompare(b.category);
              }),
            };
            updatedTemplates.unshift(newTemplate)
            return updatedTemplates;
          });

          handleClose()
          setAddingTemplate(false)
          setTemplateData({ name: '', description: '', prompt: '', selectedCategories: [], selectedCompany: null, showing_additional_options: false, isSharingWithChildAccounts: true })

          setTemplateBuilderStep(0)
          setNewTemplateStructure([])
        }
      })
      .catch(err => {
        console.log(err);
        setAddingTemplate(false)

        if (err.response.status === 403) {
          return toast.error(<div><p className='m-0'>You are using premium elements. Remove the premium elements or upgrade to Standard plan.</p><div className='d-flex align-items-center gap-2'><button className='button-plain-text text-primary' onClick={() => setTemplateBuilderStep(0)}><u>Edit</u></button> | <Link to="/upgrade">Upgrade</Link></div></div>)
        }

        toast.error("could not add template at the moment, please try again later.")
      })
  }

  const updateTemplate = async (e) => {
    e.preventDefault()

    //check if company is selected
    setCompanyNotSelected(false)
    if (selectedUser) {
      if ((selectedUser.role_id === 1 || selectedTemplate.isOfficial) && (selectedUser.is_official_template_creator || selectedUser.role_id === 1) && selectedTemplate.selectedCompany === null) {
        setCompanyNotSelected(true)
        return
      }
    }
    else {
      if ((user.role_id === 1 || selectedTemplate.isOfficial) && (user.is_official_template_creator || user.role_id === 1) && selectedTemplate.selectedCompany === null) {
        setCompanyNotSelected(true)
        return
      }
    }

    if (selectedTemplate.name.length > 200) {
      toast.error("name must be below 200 characters")
      return
    }
    if (selectedTemplate.description.length > 500) {
      toast.error("description must be below 500 characters")
      return
    }

    setAddingTemplate(true)
    const regex = /{{\s*([^{}]+)\s*}}/g;
    let prompt = JSON.stringify(selectedTemplateStructure)

    let isEnterpriseAdmin =
      (selectedUser?.plan_id ? selectedUser.plan_id === process.env.REACT_APP_PRICE_C_ID : subscriptionDetail?.price_id === process.env.REACT_APP_PRICE_C_ID)
        ? true
        : false


    // using formData since want to add files too
    const formData = new FormData()
    formData.append('user_id', user.user_id)
    formData.append('selected_user_id', selectedUser?.user_id)
    formData.append('selected_stripe_customer_id', selectedUser?.stripe_customer_id)
    formData.append('selected_role_id', selectedUser?.role_id)
    formData.append('isEnterpriseAdmin', isEnterpriseAdmin)
    formData.append('isSharingWithChildAccounts', selectedTemplate.isSharingWithChildAccounts)
    formData.append('prompt', prompt)
    formData.append('name', selectedTemplate.name)
    formData.append('description', selectedTemplate.description)
    formData.append('template_id', selectedTemplate.template_id)
    formData.append('showingAdditionalOptions', selectedTemplate.showing_additional_options)
    formData.append('selectedCategories', JSON.stringify(selectedTemplate.selectedCategories))
    formData.append('selectedCompany', JSON.stringify(selectedTemplate.selectedCompany))
    formData.append('isPublic', selectedTemplate.is_public)
    formData.append('notify_creator_on_use', selectedTemplate.notify_creator_on_use)

    selectedTemplateStructure.forEach((element, index) => {
      if ((element.type === 'pdf-context' || element.type === 'csv-context') && element.files) {
        formData.append(`files-${element.id}`, element.files)
      }
    })

    ProtectedAxios.post('/users/updateTemplate', formData)
      .then(res => {
        if (res.data) {
          // fetchTemplates()
          let indexOfSelectedTemplate = templates.findIndex(template => template.template_id === selectedTemplate.template_id);

          setTemplates(prev => {
            let updatedTemplates = [...prev];
            const currentTimestamp = new Date().toISOString();
            let updatedSelectedTemplate = {
              ...selectedTemplate,
              is_public: selectedTemplate.is_public,
              prompt: res.data.updated_prompt,
              updated_at: currentTimestamp,
              selectedCompany: selectedTemplate.selectedCompany,
              company_name: selectedTemplate?.selectedCompany?.company_name,
              company_url: selectedTemplate?.selectedCompany?.company_url,
              selectedCategories: selectedTemplate.selectedCategories.sort((a, b) => {
                return a.category.localeCompare(b.category);
              }),
            };
            updatedTemplates[indexOfSelectedTemplate] = updatedSelectedTemplate;

            return updatedTemplates;
          });

          closeEditModal()

          setTemplateEditorStep(0)

          setAddingTemplate(false)
          setSelectedTemplate({ name: '', description: '', prompt: '', selectedCategories: [] })
        }
      })
      .catch(err => {
        console.log(err);
        setAddingTemplate(false)

        if (err.response.status === 403) {
          return toast.error(<div><p className='m-0'>You are using premium elements. Remove the premium elements or upgrade to Standard plan.</p><div className='d-flex align-items-center gap-2'><button className='button-plain-text text-primary' onClick={() => setTemplateEditorStep(0)}><u>Edit</u></button> | <Link to="/upgrade">Upgrade</Link></div></div>)
        }

        toast.error(err.response.data.error || "could not update template at the moment, please try again later.")
      })
  }

  const deleteTemplate = async (_template_id) => {
    if (_template_id) {
      setDeletingTemplate(true)
      ProtectedAxios.post('/users/deleteTemplate', { user_id: user.user_id, selected_user_id: selectedUser?.user_id, template_id: _template_id })
        .then(res => {
          if (res.data) {
            setTemplates(prev => {
              let currentTemplates = [...prev]
              let updatedTemplates = currentTemplates.filter(template => template.template_id !== _template_id)
              return updatedTemplates
            })
            setDeleteTemplateConfirmationModal(false)
            setDeletingTemplate(false)
          }
        })
        .catch(err => {
          console.log(err);
          toast.error("could not delete template at the moment, please try again later.")
          setDeletingTemplate(false)
        })
    }
  }





  const [categorySearchTerm, setCategorySearchTerm] = useState("")
  const [fetchingCategories, setFetchingCategories] = useState(true)
  const [categories, setCategories] = useState([])
  const [addingNewCategory, setAddingNewCategory] = useState(false)

  const handleCategoryChange = e => {
    setCategorySearchTerm(e.target.value)
    let searchedTerm = e.target.value
    setFetchingCategories(true)
    ProtectedAxios.post("/users/getCategories", { searchedTerm })
      .then(res => {
        setFetchingCategories(false)
        setCategories(res.data)
      })
      .catch(err => {
        setFetchingCategories(false)
        console.log(err)
        toast.error(err.response.data.error)
      })
  }

  const addNewCategory = (_editingTemplate) => {
    setAddingNewCategory(true)
    ProtectedAxios.post("/users/createNewCategory", { user_id: user.user_id, selected_user_id: selectedUser?.user_id, searchedTerm: categorySearchTerm })
      .then(res => {
        if (res.data) {
          // setTemplateData(prev => { return { ...prev, selectedCategories: prev.selectedCategories.push(res.data) } })
          selectCategory(res.data, _editingTemplate)
          setCategorySearchTerm("")
          setAddingNewCategory(false)
        }
      })
      .catch(err => {
        console.log(err)
        setAddingNewCategory(false)
        toast.error(err.response.data.error)
      })
  }

  const selectCategory = (categoryData, _editingTemplate) => {
    if (_editingTemplate) {
      //check if category is already added
      let index = selectedTemplate.selectedCategories.findIndex(category => category.category === categoryData.category)
      if (index === -1) {
        setSelectedTemplate(prev => {
          let updatedCategories = [...prev.selectedCategories]
          updatedCategories.push(categoryData)
          return { ...prev, selectedCategories: updatedCategories }
        })
      }

    } else {
      //check if category is already added
      let index = templateData.selectedCategories.findIndex(category => category.category === categoryData.category)
      if (index === -1) {
        setTemplateData(prev => {
          let updatedCategories = [...prev.selectedCategories]
          updatedCategories.push(categoryData)
          return { ...prev, selectedCategories: updatedCategories }
        })
      }
    }

  }

  const removeCategory = (categoryData, _editingTemplate) => {
    if (_editingTemplate) {
      const updatedCategories = selectedTemplate.selectedCategories.filter(category => category.category_id !== categoryData.category_id)
      setSelectedTemplate(prev => { return { ...prev, selectedCategories: updatedCategories } })
    }
    else {
      const updatedCategories = templateData.selectedCategories.filter(category => category.category_id !== categoryData.category_id)
      setTemplateData(prev => { return { ...prev, selectedCategories: updatedCategories } })
    }
  }




  const [companySearchTerm, setCompanySearchTerm] = useState("")
  const [companyUrl, setCompanyUrl] = useState("")
  const [fetchingCompanies, setFetchingCompanies] = useState(true)
  const [companies, setCompanies] = useState([])
  const [addingNewCompany, setAddingNewCompany] = useState(false)
  const [showingCompanyDropdown, setShowingCompanyDropdown] = useState(false)
  const companyDropdownRef = useRef(null)

  const [editingCompany, setEditingCompany] = useState(false)
  const [editingCompanyData, setEditingCompanyData] = useState({
    company_id: undefined,
    company_name: "",
    company_url: "",
  })
  const [updatingCompany, setUpdatingCompany] = useState(false)

  useClickOutside(companyDropdownRef)

  useEffect(() => {
    setShowingCompanyDropdown(true)
    setCompanyNotSelected(false)
  }, [companySearchTerm])

  const handleCompanyChange = e => {
    setCompanySearchTerm(e.target.value)
    let searchedTerm = e.target.value
    setFetchingCompanies(true)
    if (searchedTerm.length > 0) {
      ProtectedAxios.post("/users/getCompanies", { searchedTerm })
        .then(res => {
          setFetchingCompanies(false)
          setCompanies(res.data)
        })
        .catch(err => {
          setFetchingCompanies(false)
          console.log(err)
          toast.error(err.response.data.error)
        })
    }
  }

  const addNewCompany = (_editingTemplate) => {
    setAddingNewCompany(true)
    ProtectedAxios.post("/users/createNewCompany", { searchedTerm: companySearchTerm, companyUrl })
      .then(res => {
        if (res.data) {
          selectCompany(res.data, _editingTemplate)
          setCompanySearchTerm("")
          setCompanyUrl("")
          setAddingNewCompany(false)
        }
      })
      .catch(err => {
        console.log(err)
        setAddingNewCompany(false)
        toast.error(err.response.data.error)
      })
  }

  const selectCompany = (_company, _editingTemplate) => {
    if (_editingTemplate) {
      setSelectedTemplate(prev => { return { ...prev, selectedCompany: _company } })
    } else {
      setTemplateData(prev => { return { ...prev, selectedCompany: _company } })
    }
  }

  const removeCompany = (_editingTemplate) => {
    if (_editingTemplate) {
      setSelectedTemplate(prev => { return { ...prev, selectedCompany: null } })
    }
    else {
      setTemplateData(prev => { return { ...prev, selectedCompany: null } })
    }
  }

  const editCompany = (_company) => {
    setEditingCompany(true)
    setShowingCompanyDropdown(false)

    setEditingCompanyData(
      {
        company_id: _company.company_id,
        company_name: _company.company_name,
        company_url: _company.company_url,
      }
    )
  }

  const updateCompany = (_editingTemplate) => {
    setUpdatingCompany(true)
    ProtectedAxios.post("/users/updateCompany", { company_id: editingCompanyData.company_id, company_name: editingCompanyData.company_name, company_url: editingCompanyData.company_url })
      .then(res => {
        if (res.data) {
          if (_editingTemplate) {
            setSelectedTemplate(prev => {
              return {
                ...prev,
                selectedCompany: {
                  company_id: res.data.company_id,
                  company_name: res.data.company_name,
                  company_url: res.data.company_url,
                }
              }
            })
          } else {
            setTemplateData(prev => {
              return {
                ...prev,
                selectedCompany: {
                  company_id: res.data.company_id,
                  company_name: res.data.company_name,
                  company_url: res.data.company_url,
                }
              }
            })
          }

          setCompanySearchTerm("")
          setEditingCompany(false)
          setUpdatingCompany(false)
        }
      })
      .catch(err => {
        setUpdatingCompany(false)
        console.log(err)
        toast.error(err.response.data.error)
      })
  }






  const templateDropdown = useRef(null)
  const [showingTemplateDropdown, setShowingTemplateDropdown] = useState(true)
  useClickOutside(templateDropdown)

  useEffect(() => {
    setShowingTemplateDropdown(true)
  }, [categorySearchTerm])

  function useClickOutside(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowingTemplateDropdown(false)
          setShowingCompanyDropdown(false)
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }






  const [selectedFilterCategories, setSelectedFilterCategories] = useState([])
  const [fetchingTemplateCategories, setFetchingTemplateCategories] = useState(false)
  const [templateCategories, setTemplateCategories] = useState([])
  const [backupTemplateCategories, setBackupTemplateCategories] = useState([])
  const [searchTemplateCategoryText, setSearchTemplateCategoryText] = useState("")

  useEffect(() => {
    if (categoryDropdownVisible) {
      fetchTemplateCategories()
    }
  }, [categoryDropdownVisible])

  const fetchTemplateCategories = () => {
    setFetchingTemplateCategories(true)
    ProtectedAxios.post("/users/fetchUsersTemplateCategories", { user_id: user.user_id, selected_user_id: selectedUser?.user_id, selectedCategories: selectedFilterCategories })
      .then(res => {
        setTemplateCategories(res.data)
        setBackupTemplateCategories(res.data)
        setFetchingTemplateCategories(false)
      })
      .catch(err => {
        console.log(err)
        toast.error("Could not fetch categories at the moment please try again later.")
        setFetchingTemplateCategories(false)
      })
  }

  const selectTemplateCategory = (_category) => {
    setSearchTemplateCategoryText("")
    setSelectedFilterCategories(prev => {
      let updatedCategories = [...prev]
      updatedCategories.push(_category)
      fetchTemplates(undefined, updatedCategories)
      return updatedCategories
    })
  }

  const removeTemplateCategory = (_category) => {
    const filteredCategories = selectedFilterCategories.filter(category => category.category_id !== _category.category_id)
    setSelectedFilterCategories(filteredCategories)
    fetchTemplates(undefined, filteredCategories)
  }

  useEffect(() => {
    filterTemplateCategoryList()
  }, [searchTemplateCategoryText])
  const filterTemplateCategoryList = () => {
    if (searchTemplateCategoryText.length > 0) {
      const updatedList = backupTemplateCategories.filter(category => {
        return category.category.toLowerCase().includes(searchTemplateCategoryText.toLowerCase())
      })
      setTemplateCategories(updatedList)
    } else {
      setTemplateCategories(backupTemplateCategories)
    }
  }






  const [resettingTemplate, setResettingTemplate] = useState(false)
  const resetTemplateToDefaultMarketplaceValues = (e) => {
    e.preventDefault();
    setResettingTemplate(true)
    ProtectedAxios.post("/users/resetTemplate", { user_id: user.user_id, selected_user_id: selectedUser?.user_id, template_id: selectedTemplate?.template_id })
      .then(res => {

        setResettingTemplate(false)
      })
      .catch(err => {
        console.log(err)
        toast.error("Couldn't reset template at the moment please try again later.")
        setResettingTemplate(false)
      })
  }






  const categoryFilterDropdownRef = useRef(null)
  useClickOutside(categoryFilterDropdownRef)
  function useClickOutside(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setCategoryDropdownVisible(false)
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }




  const [duplicatingTemplate, setDuplicatingTemplate] = useState(false)
  const [duplicatateTemplateModalState, setDuplicateTemplateModalState] = useState(false)
  const duplicateTemplate = () => {
    if (selectedTemplate?.template_id) {
      setDuplicatingTemplate(true)
      ProtectedAxios.post("/users/template/duplicate", { selectedTemplate })
        .then(res => {
          setDuplicatingTemplate(false)
          setTemplates(prev => {
            prev.unshift(res.data)
            return prev
          })
          setDuplicateTemplateModalState(false)
          toast.success(`Duplicated template: ${selectedTemplate.name}`)
        })
        .catch(err => {
          setDuplicatingTemplate(false)
          toast.error("Couldn't duplicate template at the moment")
        })
    }
  }



  // 
  // Using rendering functions because most of the elements in templateBuilder and templateEditor were repeated
  // 
  const renderTemplateElements = (selectedTemplateData) => {
    const templateBuilderElements = [
      {
        category: "static",
        items: [
          {
            type: "text",
            displayName: "Text",
            icon: <PiTextAa />,
            premium: false,
            can_be_private: false,
            private: false,
            supported_type: ["CHAT_STARTER_PRIVATE", "CHAT_STARTER_OPEN", "NORMAL"]
          },
          {
            type: "hidden-text",
            displayName: "Hidden Text",
            icon: <VscGistSecret />,
            premium: true,
            can_be_private: true,
            private: false,
            supported_type: ["CHAT_STARTER_PRIVATE", "CHAT_STARTER_OPEN"]
          },
        ],
      },
      {
        category: "dynamic",
        items: [
          {
            type: "textarea",
            displayName: "Textarea",
            icon: <BsTextareaT />,
            premium: false,
            can_be_private: false,
            private: false,
            supported_type: ["CHAT_STARTER_PRIVATE", "CHAT_STARTER_OPEN", "NORMAL"]
          },
          {
            type: "select",
            displayName: "Select",
            icon: <GoMultiSelect />,
            premium: false,
            can_be_private: false,
            private: false,
            supported_type: ["CHAT_STARTER_PRIVATE", "CHAT_STARTER_OPEN", "NORMAL"]
          },
        ],
      },
      {
        category: "context",
        items: [
          {
            type: "plain-text-context",
            displayName: "Plain Text",
            icon: <MdTextRotationNone />,
            premium: true,
            can_be_private: true,
            private: false,
            supported_type: ["CHAT_STARTER_PRIVATE", "CHAT_STARTER_OPEN"]
          },
          {
            type: "pdf-context",
            displayName: "PDF",
            icon: <FaRegFilePdf />,
            premium: true,
            can_be_private: true,
            private: false,
            supported_type: ["CHAT_STARTER_PRIVATE", "CHAT_STARTER_OPEN"]
          },
          {
            type: "csv-context",
            displayName: "CSV",
            icon: <RiFileExcel2Line />,
            premium: true,
            can_be_private: true,
            private: false,
            supported_type: ["CHAT_STARTER_PRIVATE", "CHAT_STARTER_OPEN"]
          },
        ],
      },
    ]

    return templateBuilderElements.map((templateBuilderElement, i) => {
      if (templateBuilderElement.items.filter(item => item.supported_type.includes(selectedTemplateData?.type)).length > 0) {
        return <div key={i} className='template-items-category'>
          <div className='template-item-header'>
            <p className='text-capitalize'>{templateBuilderElement.category}</p>
            <span />
          </div>

          <div className='template-items'>
            {templateBuilderElement.items.map((item, j) => {
              if (item.supported_type.includes(selectedTemplateData?.type)) {
                return <div key={j} className='drag-item-container'>
                  <DragDropContainer
                    dragData={{ type: item.type, displayName: item.displayName, category: templateBuilderElement.category, premium: item.premium, private: item.private, can_be_private: item.can_be_private }}
                    targetKey="template-builder-area"
                    customDragElement={
                      <div className='template-item'>
                        {item.icon}
                        <p>{item.displayName}</p>
                      </div>
                    }
                  >
                    {item.premium
                      &&
                      <FaCrown className='prem-icon' />
                    }
                    <div className='template-item'>
                      {item.icon}
                      <p>{item.displayName}</p>
                    </div>
                  </DragDropContainer>
                  <button
                    className='edit-btn'
                    value={JSON.stringify({ type: item.type, displayName: item.displayName, category: templateBuilderElement.category, premium: item.premium, private: item.private, can_be_private: item.can_be_private })}
                    onClick={e => addElement(JSON.parse(e.target.value), newTemplateStructure, setNewTemplateStructure)}
                  >
                    <GrFormAdd />
                  </button>
                </div>
              }
            })}
          </div>

        </div>
      }
    })
  }

  const renderSelectedTemplateElements = (templateStructure, templateStructureSetter, handleTemplateItemSort) => {
    return <DropTarget
      targetKey="template-builder-area"
      onHit={(e) => {
        addElement(e.dragData, templateStructure, templateStructureSetter)
      }}
    >
      <div className='template-elements-container'>
        {templateBuilderError.length > 0
          &&
          <div className='border rounded p-2 mb-3 d-flex justify-content-between align-items-center gap-2'>
            <p className='text-danger m-0 font-xxs'>{templateBuilderError}</p>
            <button
              className='edit-btn'
              style={{ flex: "none" }}
              title='hide'
              onClick={e => {
                setTemplateBuilderError("")
              }}>
              <span className='edit-icon'>-</span>
            </button>
          </div>
        }

        {templateStructure.length === 0
          ?
          <div className='w-100 d-flex flex-column justify-content-center align-items-center' style={{ height: "50dvh" }}>
            <div className='d-flex flex-column'>
              <p className='text-center no-elements-message' style={{ color: "var(--color-light)" }}>
                Drag the elements here <LiaHandRock className='font-m' />
                <br />
                or click the + icon next to the element
              </p>
            </div>
          </div>

          :
          <div>
            {templateStructure.map((element, i) => {
              return (
                <>
                  <DropTarget
                    id={`element-drop-target-${i}`}
                    targetKey="template-element"
                    highlightClassName="element-dropping"
                    onHit={e => {
                      handleTemplateItemSort(e)
                    }}
                  >
                    <div className="item-drop-indicator"><TbDragDrop2 /><p>drop here</p></div>
                    <div
                      id={`element-item-${i}`}
                      className={`template-element pt-4 ${element.error.length > 0 && 'border border-danger'}`}
                      key={i}
                    >
                      {element?.premium
                        &&
                        <FaCrown className='prem-icon' style={{ top: "var(--size-us)", right: "var(--size-us)" }} />
                      }
                      {element.error.length > 0
                        &&
                        <div className='border rounded p-2 mb-3 d-flex justify-content-between align-items-center gap-2'>
                          <p className='text-danger m-0 font-xxs'>{element.error}</p>
                          <button
                            className='edit-btn'
                            style={{ flex: "none" }}
                            title='hide'
                            onClick={e => {
                              templateStructureSetter(prev => {
                                let updatedData = [...prev]
                                updatedData[i].error = ''
                                return updatedData
                              })
                            }}>
                            <span className='edit-icon'>-</span>
                          </button>
                        </div>
                      }
                      <div className='element-header'>

                        <DragDropContainer
                          dragData={{ index: i }}
                          targetKey="template-element"
                        >
                          <div className='left'>
                            <MdDragIndicator className='edit-icon' />
                            <p>{element.displayName}</p>
                          </div>
                        </DragDropContainer>

                        <button className='edit-btn' title='remove element' onClick={e => deleteElement(element, templateStructure, templateStructureSetter)}><RiDeleteBin4Line className='edit-icon' /></button>
                      </div>
                      {element.type === "text"
                        &&
                        <>
                          <label for={`textarea-${element.id}`} className='label font-xxs'>Content</label>
                          <textarea
                            id={`textarea-${element.id}`}
                            value={templateStructure[i].content}
                            onChange={e => {
                              templateStructureSetter(prev => {
                                let updatedData = [...prev]
                                updatedData[i].content = e.target.value
                                updatedData[i].error = ""
                                return updatedData
                              })
                            }}
                          />
                        </>
                      }

                      {element.type === "hidden-text"
                        &&
                        <>
                          <label for={`textarea-${element.id}`} className='label font-xxs'>Content</label>
                          <textarea
                            id={`textarea-${element.id}`}
                            value={templateStructure[i].content}
                            onChange={e => {
                              templateStructureSetter(prev => {
                                let updatedData = [...prev]
                                updatedData[i].content = e.target.value
                                updatedData[i].error = ""
                                return updatedData
                              })
                            }}
                          />
                        </>
                      }

                      {element.type === "textarea"
                        &&
                        <>
                          <label for={`input-${element.id}`} className='label font-xxs'>Label for textarea</label>
                          <input
                            id={`input-${element.id}`}
                            value={templateStructure[i].label}
                            onChange={e => {
                              templateStructureSetter(prev => {
                                let updatedData = [...prev]
                                updatedData[i].label = e.target.value
                                updatedData[i].error = ""
                                return updatedData
                              })
                            }}
                          />
                        </>
                      }

                      {element.type === "select"
                        &&
                        <>
                          <label for={`input-${element.id}`} className='label font-xxs'>Label for select</label>
                          <input
                            id={`input-${element.id}`}
                            value={templateStructure[i].label}
                            onChange={e => {
                              templateStructureSetter(prev => {
                                let updatedData = [...prev]
                                updatedData[i].label = e.target.value
                                updatedData[i].error = ""
                                return updatedData
                              })
                            }}
                          />
                          <hr />
                          <p className='font-xxs m-0'>Options: </p>
                          {element.options.length === 0
                            ? <p className="font-xxs" style={{ color: "var(--color-light)" }}>No options added</p>
                            :
                            <div className='options'>
                              {element.options.map((option, option_key) => {
                                return (
                                  <span
                                    key={option_key}
                                    title={`value: ${option.value}`}
                                    className={`tag tag-color-${option_key % 4 + 1} editing`}
                                  >
                                    {option.key}
                                    <button
                                      type='button'
                                      title='remove option'
                                      id={`remove-option-button-${element.id}-${option_key}`}
                                      className='edit-btn remove-tag-button'
                                      onClick={e => {
                                        templateStructureSetter(prev => {
                                          let updatedData = [...prev]
                                          let currentOptions = updatedData[i].options
                                          let updatedOptions = currentOptions.filter(_option => _option !== option)

                                          updatedData[i].options = updatedOptions
                                          return updatedData
                                        })
                                      }}
                                    >
                                      <GrFormClose className={`edit-icon`} />
                                    </button>
                                  </span>
                                )
                              })}
                            </div>
                          }
                          <form
                            onSubmit={e => {
                              e.preventDefault()
                              let key = e.target[0].value
                              let value = e.target[1].value

                              templateStructureSetter(prev => {
                                let updatedData = [...prev]
                                updatedData[i].options.push({ key, value })
                                updatedData[i].error = ""
                                return updatedData
                              })

                              //clear the values
                              e.target[0].value = ""
                              e.target[1].value = ""

                              e.target[1].blur()
                            }}
                          >
                            <div className='d-flex gap-2'>
                              <div className='w-50'>
                                <label className='font-xxs' for={`key-input-${element.id}`}>Key</label>
                                <input required id={`key-input-${element.id}`} />
                              </div>
                              <div className='w-50'>
                                <label className='font-xxs' for={`value-input-${element.id}`}>Value</label>
                                <input required id={`value-input-${element.id}`} />
                              </div>
                            </div>
                            <div className='w-100 d-flex justify-content-end'>
                              <button type='submit' className='button-ghost font-us p-2 px-4 mt-2 rounded border-0'>Add Option</button>
                            </div>
                          </form>
                        </>
                      }

                      {element.type === "plain-text-context"
                        &&
                        <>
                          <label for={`textarea-plain-text-context-${element.id}`} className='label font-xxs'>Content</label>
                          <textarea
                            id={`textarea-plain-text-context-${element.id}`}
                            value={templateStructure[i].content}
                            onChange={e => {
                              templateStructureSetter(prev => {
                                let updatedData = [...prev]
                                updatedData[i].content = e.target.value
                                updatedData[i].error = ""
                                return updatedData
                              })
                            }}
                          />
                        </>
                      }


                      {element.type === "pdf-context" && (
                        <>
                          <div className="file-upload-container">
                            {
                              (templateStructure[i].content && templateStructure[i].filename)
                                ?
                                <div className='selected-file-details py-2'>
                                  <span>{templateStructure[i].filename}</span>
                                  <div className='d-flex align-items-center'>
                                    <button
                                      className='edit-btn'
                                      title="remove file"
                                      onClick={() => {
                                        templateStructureSetter((prev) => {
                                          let updatedData = [...prev];
                                          updatedData[i].files = null;
                                          delete updatedData[i].content
                                          delete updatedData[i].filename

                                          return updatedData;
                                        });
                                      }}
                                    >
                                      <MdClose className='w-xs h-xs' />
                                    </button>

                                    {/* <button
                                      className='edit-btn'
                                      title="download file"
                                      onClick={() => {
                                        
                                      }}
                                    >
                                      <RiDownloadLine className='w-xs h-xs' />
                                    </button> */}
                                  </div>
                                </div>

                                : templateStructure[i].files
                                  ?
                                  <div className='selected-file-details'>
                                    <span>{templateStructure[i].files.name}</span>
                                    <button
                                      className='edit-btn'
                                      onClick={() => {
                                        templateStructureSetter((prev) => {
                                          let updatedData = [...prev];
                                          updatedData[i].files = null;
                                          return updatedData;
                                        });
                                      }}
                                    >
                                      ×
                                    </button>
                                  </div>

                                  :
                                  <>
                                    <p className='file-upload-label'>Drop file or click to upload</p>
                                    <input
                                      type="file"
                                      accept='.pdf'
                                      id={`input-pdf-context-${element.id}`}
                                      className='template-builder-file-selector'
                                      onChange={e => {
                                        // Ensure only one file is selected
                                        const file = e.target.files[0];
                                        if (!file || file.type !== "application/pdf") {
                                          // Handle invalid file types or no file selected
                                          templateStructureSetter((prev) => {
                                            let updatedData = [...prev];
                                            updatedData[i].files = null; // Clear the files
                                            updatedData[i].error = "Please select a valid PDF file.";
                                            return updatedData;
                                          });
                                          return; // Exit the function
                                        }

                                        templateStructureSetter((prev) => {
                                          let updatedData = [...prev];
                                          updatedData[i].files = file; // Store the single file
                                          updatedData[i].error = "";
                                          return updatedData;
                                        });
                                      }}
                                    />
                                  </>
                            }
                          </div>
                        </>
                      )}


                      {element.type === "csv-context" && (
                        <>
                          <div className="file-upload-container">
                            {
                              (templateStructure[i].content && templateStructure[i].filename)
                                ?
                                <div className='selected-file-details py-2'>
                                  <span>{templateStructure[i].filename}</span>
                                  <div className='d-flex align-items-center'>
                                    <button
                                      className='edit-btn'
                                      title="remove file"
                                      onClick={() => {
                                        templateStructureSetter((prev) => {
                                          let updatedData = [...prev];
                                          updatedData[i].files = null;
                                          delete updatedData[i].content
                                          delete updatedData[i].filename

                                          return updatedData;
                                        });
                                      }}
                                    >
                                      <MdClose className='w-xs h-xs' />
                                    </button>

                                    {/* <button
                                      className='edit-btn'
                                      title="download file"
                                      onClick={() => {
                                        
                                      }}
                                    >
                                      <RiDownloadLine className='w-xs h-xs' />
                                    </button> */}
                                  </div>
                                </div>

                                : templateStructure[i].files
                                  ?
                                  <div className='selected-file-details'>
                                    <span>{templateStructure[i].files.name}</span>
                                    <button
                                      className='edit-btn'
                                      onClick={() => {
                                        templateStructureSetter((prev) => {
                                          let updatedData = [...prev];
                                          updatedData[i].files = null;
                                          return updatedData;
                                        });
                                      }}
                                    >
                                      ×
                                    </button>
                                  </div>

                                  :
                                  <>
                                    <p className='file-upload-label'>Drop file or click to upload</p>
                                    <input
                                      type="file"
                                      accept='.csv'
                                      id={`input-pdf-context-${element.id}`}
                                      className='template-builder-file-selector'
                                      onChange={e => {
                                        // Ensure only one file is selected
                                        const file = e.target.files[0];
                                        if (!file || file.type !== "text/csv") {
                                          // Handle invalid file types or no file selected
                                          templateStructureSetter((prev) => {
                                            let updatedData = [...prev];
                                            updatedData[i].files = null; // Clear the files
                                            updatedData[i].error = "Please select a valid CSV file.";
                                            return updatedData;
                                          });
                                          return; // Exit the function
                                        }

                                        templateStructureSetter((prev) => {
                                          let updatedData = [...prev];
                                          updatedData[i].files = file; // Store the single file
                                          updatedData[i].error = "";
                                          return updatedData;
                                        });
                                      }}
                                    />
                                  </>
                            }
                          </div>
                        </>
                      )}



                      {element.can_be_private
                        &&
                        <div className='form-check mt-1'>
                          <input
                            class="form-check-input"
                            id={`private-status-checkbox-${i}`}
                            type="checkbox"
                            checked={templateStructure[i].private}
                            onChange={e => {
                              templateStructureSetter(prev => {
                                let updatedData = [...prev]
                                updatedData[i].private = !updatedData[i].private
                                return updatedData
                              })
                            }}
                          />
                          <label class="mx-2 form-check-label text-secondary font-us cursor-pointer" htmlFor={`private-status-checkbox-${i}`}>Keep Private (content of this element won't be copied when template is duplicated or installed)</label>
                        </div>
                      }

                    </div>
                    {/* </DragDropContainer> */}
                  </DropTarget>
                </>
              )
            })}
          </div>
        }
      </div>
    </DropTarget>
  }

  const renderSelectedTemplatePreview = (templateStructure, templateStructureSetter, disabledFields = false) => {

    return <div className='form-container'>
      {
        templateStructure.map((element, i) => {
          return (
            <div className={``} key={i} id={`element-item-${element.id}`}>

              {element.type === "text"
                &&
                <>
                  <p>{templateStructure[i].content}</p>
                </>
              }

              {element.type === "hidden-text"
                &&
                <div className='text-secondary'>
                  <span className='font-us'>This element will be hidden in template</span>
                  <p>{templateStructure[i].content}</p>
                </div>
              }

              {element.type === "textarea"
                &&
                <>
                  <label for={`input-${element.id}`} className='label font-xxs'>{templateStructure[i].label}</label>
                  <input
                    id={`input-${element.id}`}
                    title={disabledFields ? "fields are disabled for preview" : ""}
                    disabled={disabledFields}
                    value={templateStructure[i]?.value}
                    onChange={e => {
                      templateStructureSetter(prev => {
                        let updatedData = [...prev]
                        updatedData[i].value = e.target.value
                        return updatedData
                      })
                    }}
                  />
                </>
              }

              {element.type === "select"
                &&
                <>
                  <label for={`input-${element.id}`} className='label font-xxs'>{element.label}</label>
                  <select
                    className='form-select'
                    title={disabledFields ? "fields are disabled for preview" : ""}
                    disabled={disabledFields}
                    id={`input-${element.id}`}
                    value={templateStructure[i]?.value}
                    onChange={e => {
                      templateStructureSetter(prev => {
                        let updatedData = [...prev]
                        updatedData[i].value = e.target.value
                        return updatedData
                      })
                    }}
                  >
                    {element.options.map((option, option_key) => {
                      return (
                        <option
                          key={option_key}
                          value={option.value}
                        >
                          {option.key}
                        </option>
                      )
                    })}
                  </select>
                </>
              }

            </div>
          )
        })
      }
    </div>
  }

  const renderSelectedTemplateDetailsForm = (selectedTemplateData, selectedTemplateDataSetter, editingTemplate = false) => {
    return <>
      {(selectedUser?.role_id ? selectedUser?.role_id !== 1 : user.role_id !== 1)
        &&
        <div className='form-check d-sm-block'>
          <input className='form-check-input' id='publicAccessCheckbox' type='checkbox' checked={selectedTemplateData.is_public} onClick={e => selectedTemplateDataSetter({ ...selectedTemplateData, is_public: !selectedTemplateData.is_public, notify_creator_on_use: !selectedTemplateData.is_public === false ? false : selectedTemplateData.notify_creator_on_use })} />
          <label className="form-check-labe" htmlFor="publicAccessCheckbox" style={{ marginLeft: "5px" }}>
            Public
            <OverlayTrigger
              delay={{ hide: 450, show: 300 }}
              overlay={(props) => (
                <Tooltip {...props}>
                  Upon activating this option, your template will be accessible to all users. Users will have the ability to try and install it directly from the Template Marketplace.<br />(You can always set it to private again)
                </Tooltip>
              )}
              placement="right"
            >
              <button type='button' className='tooltip-button'><BsFillInfoCircleFill /></button>
            </OverlayTrigger>
          </label>
        </div>
      }

      {(selectedUser ? (selectedUser?.is_official_template_creator && selectedTemplateData.is_public) : (user?.is_official_template_creator && selectedTemplateData.is_public))
        &&
        <div className='form-check d-sm-block'>
          <input
            className='form-check-input'
            id='notifyCreatorCheckbox'
            type='checkbox'
            checked={selectedTemplateData.notify_creator_on_use}
            onClick={e => selectedTemplateDataSetter({ ...selectedTemplateData, notify_creator_on_use: !selectedTemplateData.notify_creator_on_use })}
          />
          <label className="form-check-labe" htmlFor="notifyCreatorCheckbox" style={{ marginLeft: "5px" }}>
            Notify me on use
            <OverlayTrigger
              delay={{ hide: 450, show: 300 }}
              overlay={(props) => (
                <Tooltip {...props}>
                  You will receive an email whenever a user uses this public template. Users will see a message informing them that their details will be shared with the template creator.
                </Tooltip>
              )}
              placement="right"
            >
              <button type='button' className='tooltip-button'><BsFillInfoCircleFill /></button>
            </OverlayTrigger>
          </label>
        </div>
      }

      {(selectedUser ? (selectedUser?.role_id !== 1 && selectedUser?.is_official_template_creator) : (user.role_id !== 1 && user?.is_official_template_creator))
        &&
        <div className='form-check d-sm-block'>
          <input
            className='form-check-input'
            id='officialCheckbox'
            type='checkbox'
            checked={selectedTemplateData.isOfficial}
            onClick={e => {
              if (!selectedTemplateData.isOfficial === false) {
                selectedTemplateDataSetter({ ...selectedTemplateData, isOfficial: !selectedTemplateData.isOfficial, selectedCompany: null })
              } else {
                selectedTemplateDataSetter({ ...selectedTemplateData, isOfficial: !selectedTemplateData.isOfficial })
              }
            }}
          />
          <label className="form-check-labe" htmlFor="officialCheckbox" style={{ marginLeft: "5px" }}>
            Official
            <OverlayTrigger
              delay={{ hide: 450, show: 300 }}
              overlay={(props) => (
                <Tooltip {...props}>
                  Official templates will be seen under the offical section of the template marketplace. You will have an additional option to add company details in official template.
                </Tooltip>
              )}
              placement="right"
            >
              <button type='button' className='tooltip-button'><BsFillInfoCircleFill /></button>
            </OverlayTrigger>
          </label>
        </div>
      }

      <div className='input-grp'>
        <label htmlFor="name">Name <span className='text-danger'>*</span></label>
        <input required autoFocus type="text" id="name" value={selectedTemplateData.name} onChange={e => selectedTemplateDataSetter({ ...selectedTemplateData, name: e.target.value })} />
      </div>

      <div className='input-grp'>
        <label htmlFor="description">Description <span className='text-danger'>*</span></label>
        <textarea required type="text" id="description" value={selectedTemplateData.description} onChange={e => selectedTemplateDataSetter({ ...selectedTemplateData, description: e.target.value })} />
      </div>

      {(selectedUser ? (selectedUser?.role_id === 1 || (selectedUser?.is_official_template_creator && selectedTemplateData.isOfficial)) : (user.role_id === 1 || (user.is_official_template_creator && selectedTemplateData.isOfficial)))
        &&
        <div>
          <div className='input-grp template-grp'>
            <label htmlFor="company-select" className={`${companyNotSelected ? "text-danger" : ""}`}>Select Company</label>
            <input type="text" id="company-select" className={`${companyNotSelected ? "border-danger" : ""}`} value={companySearchTerm} onChange={handleCompanyChange} />
            {companySearchTerm.length > 0 && showingCompanyDropdown
              &&
              <div ref={companyDropdownRef} className='list-dropdown md' style={{ minHeight: "auto", maxHeight: "30vh" }}>
                {fetchingCompanies
                  ?
                  <div className='py-3 opacity-50'>
                    <div className="d-flex m-auto spinner-border spinner-border" role="status">
                      <span className="sr-only"></span>
                    </div>
                  </div>

                  :
                  <div className='list-container'>
                    {companies.length === 0
                      ?
                      <>
                        <p className='pt-3 px-3'>
                          <div>
                            <p>No result found, Create a new company</p>
                            <label htmlFor="company-name">Company Name</label>
                            <input type="text" id="company-name" value={companySearchTerm} onChange={e => setCompanySearchTerm(e.target.value)} />

                            <label htmlFor="company-url" className='mt-4'>Company URL</label>
                            <input type='text' id="company-url" value={companyUrl} onChange={e => setCompanyUrl(e.target.value)} className='mb-4' placeholder='company url' />

                            <button type="submit" className='button button-ghost' onClick={() => addNewCompany(editingTemplate)} disabled={addingNewCompany}>
                              {addingNewCompany
                                ?
                                <>
                                  Create
                                  <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                    <span className="sr-only"></span>
                                  </div>
                                </>

                                : "Create"
                              }
                            </button>
                          </div>
                        </p>
                      </>

                      :
                      <>
                        {companies.map((company, i) => {
                          return (
                            <div className='dropdown-company-element' key={i}>
                              <div className='company-detail' onClick={() => {
                                selectCompany(company, editingTemplate)
                                setCompanySearchTerm("")
                              }
                              }>
                                <div>
                                  {company.company_name}
                                  <br />
                                  <span className='subtitle'>{company.company_url.substring(0, 40)}{company.company_url.length > 40 && "..."}</span>
                                </div>
                              </div>
                              <div className='button-container'>
                                <button title="edit" className='edit-btn' onClick={() => editCompany(company)}><AiFillEdit className='edit-icon' style={{ fontSize: 'var(--fs-2)' }} /></button>
                              </div>
                            </div>
                          )
                        })}
                      </>
                    }
                  </div>
                }
              </div>
            }
            {editingCompany
              &&
              <div className='template-dropdown'>
                <div className='template-container'>
                  <p className='pt-3 px-3'>
                    <div>
                      <p className='d-flex align-items-center'>
                        <button type='button' title="edit" className='edit-btn' onClick={() => { setEditingCompany(false) }}><MdArrowBack className='edit-icon' style={{ fontSize: 'var(--fs-2)' }} /></button>
                        Edit Company
                      </p>
                      <label htmlFor="company-name">Company Name</label>
                      <input type="text" id="company-name" value={editingCompanyData.company_name} onChange={e => setEditingCompanyData(prev => { return { ...prev, company_name: e.target.value } })} />

                      <label htmlFor="company-url" className='mt-4'>Company URL</label>
                      <input type='text' id="company-url" value={editingCompanyData.company_url} onChange={e => setEditingCompanyData(prev => { return { ...prev, company_url: e.target.value } })} className='mb-4' placeholder='company url' />

                      <button type="submit" className='button button-ghost' onClick={() => updateCompany(editingTemplate)} disabled={updatingCompany}>
                        {updatingCompany
                          ?
                          <>
                            Update
                            <div className="mx-2 spinner-border spinner-border-sm" role="status">
                              <span className="sr-only"></span>
                            </div>
                          </>

                          : "Update"
                        }
                      </button>
                    </div>
                  </p>
                </div>
              </div>
            }
          </div>
          <div className='selected-tag-container'>
            {selectedTemplateData.selectedCompany !== null && selectedTemplateData.selectedCompany?.company_id
              ?
              <span className={`tag tag-color-${0 % 4 + 1} editing`}>{selectedTemplateData.selectedCompany.company_name} <button type='button' title='remove company' id='remove-tag-button' className='edit-btn remove-tag-button' onClick={e => { removeCompany(editingTemplate) }}><GrFormClose className={`edit-icon`} /></button></span>

              :
              <span className={`subtitle ${companyNotSelected ? "text-danger" : ""}`} style={{ marginTop: "-0.7rem" }}>no company selected</span>
            }
          </div>
        </div>
      }

      <div>
        <div className='input-grp template-grp'>
          <label htmlFor="category-select">Add Category</label>
          <div className='tag-input-container full' style={{ display: 'flex' }}>
            <input type="text" id="category-select" value={categorySearchTerm} onChange={handleCategoryChange} />
            <button type='button' className='edit-btn' title='add tag' disabled={addingNewCategory || categorySearchTerm.length <= 0} onClick={() => addNewCategory(editingTemplate)}>
              {addingNewCategory
                ?
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="sr-only"></span>
                </div>
                :
                <GrFormAdd className='edit-icon accept-icon ' />
              }
            </button>
          </div>
          {categorySearchTerm.length > 0 && showingTemplateDropdown
            &&
            <div ref={templateDropdown} className='list-dropdown md'>
              {fetchingCategories
                ?
                <div className='py-3 opacity-50'>
                  <div className="d-flex m-auto spinner-border spinner-border" role="status">
                    <span className="sr-only"></span>
                  </div>
                </div>

                :
                <div className='list-container'>
                  {categories.length === 0
                    ?
                    <p className='text-secondary text-center my-3 px-2 font-xxs'>No cateogry found, please complete the name and click + icon to add this category</p>

                    :
                    <>
                      {categories.map((category, i) => {
                        return (
                          <div className='list-item d-flex flex-wrap gap-3' key={i}
                            onClick={() => {
                              selectCategory(category, editingTemplate)
                              setCategorySearchTerm("")
                            }
                            }
                          >
                            {category.category.toLowerCase()}
                          </div>
                        )
                      })}
                    </>
                  }
                </div>
              }
            </div>
          }
        </div>
        <div className='selected-tag-container'>
          {selectedTemplateData.selectedCategories?.length > 0
            ?
            <>
              {selectedTemplateData.selectedCategories.map((category, i) => {
                return (
                  <span key={i} className={`tag tag-color-${i % 4 + 1} editing`}>{category.category.toLowerCase()} <button type='button' title='remove category' id='remove-tag-button' className='edit-btn remove-tag-button' onClick={e => { removeCategory(category, editingTemplate) }}><GrFormClose className={`edit-icon`} /></button></span>
                )
              })}
            </>
            :
            <span className='subtitle' style={{ marginTop: "-0.7rem" }}>no category selected</span>
          }
        </div>
      </div>


      <div className='form-check d-sm-block'>
        <input className='form-check-input' id='additionalOptionsCheckbox' type='checkbox' checked={selectedTemplateData.showing_additional_options} onClick={e => selectedTemplateDataSetter({ ...selectedTemplateData, showing_additional_options: !selectedTemplateData.showing_additional_options })} />
        <label className="form-check-labe" style={{ marginLeft: "5px" }} htmlFor="additionalOptionsCheckbox">
          Enable Style and Tone selection
          <OverlayTrigger
            delay={{ hide: 450, show: 300 }}
            overlay={(props) => (
              <Tooltip {...props}>
                By enabling this option, you will be able to choose from a selection of predefined styles and tones for your prompt. This allows you to tailor the language and manner in which the AI responds to your input.
              </Tooltip>
            )}
            placement="right"
          >
            <button type='button' className='tooltip-button'><BsFillInfoCircleFill /></button>
          </OverlayTrigger>
        </label>
      </div>

      {(selectedUser?.plan_id ? selectedUser.plan_id === process.env.REACT_APP_PRICE_C_ID : subscriptionDetail?.price_id === process.env.REACT_APP_PRICE_C_ID)
        &&
        <div className='input-grp flex-row gap-2 align-items-end'>
          <input className='form-check-input' id='shareWithChildAccountsCheckbox' type='checkbox' checked={selectedTemplateData.isSharingWithChildAccounts} onClick={e => selectedTemplateDataSetter({ ...selectedTemplateData, isSharingWithChildAccounts: !selectedTemplateData.isSharingWithChildAccounts })} />
          <label className="form-check-label" htmlFor="shareWithChildAccountsCheckbox" style={{ marginLeft: "5px" }}>
            Share with all my child accounts
          </label>
        </div>
      }
    </>
  }





  return (
    <div className='container py-5'>
      <Helmet>
        <title>Templates - SageCollab</title>
      </Helmet>

      {(selectedUser ? selectedUser?.role_id === 1 : user.role_id === 1)
        &&
        <h2>Create Templates for Marketplace</h2>
      }
      {(selectedUser ? selectedUser?.role_id === 1 : user.role_id === 1)
        ?
        <p>Create prompt templates that users can install from template marketplace and use/edit them as their own.</p>

        :
        <>
        </>
      }

      <div className='template-header'>
        <div className='w-100 d-flex justify-content-end align-items-center my-4 gap-3'>
          {/* <input type="text" placeholder='search template' value={templateSearchText} onChange={e => setTemplateSearchText(e.target.value)} /> */}
          <form className='input-with-icon w-100' onSubmit={fetchTemplates}>
            <input
              className='form-input'
              type="text"
              placeholder="search template"
              value={templateSearchText}
              onChange={e => setTemplateSearchText(e.target.value)}
            />
            {templateSearchText.length
              ?
              <button type="submit" className='button-icon edit-btn' title='search'><MdSearch className='edit-icon reject-icon ' style={{ fill: '#404040' }} /></button>
              :
              <></>
            }
          </form>

          <div className={`category-select-dropdown ${categoryDropdownVisible ? 'dropdown-active' : ''} position-relative`}>
            <button className='edit-btn' onClick={() => setCategoryDropdownVisible(prev => !prev)}>
              <BiFilter className='edit-icon' style={{ fontSize: 'var(--fs-2)' }} />
            </button>
            <div ref={categoryFilterDropdownRef} className={`searchable-dropdown ${categoryDropdownVisible ? 'dropdown-active' : ''}`}>
              <div className="dropdown-content">
                <div className="dropdown-header">
                  <div className='dropdown-title'>
                    <p>Filter by cateogry</p>
                  </div>
                  <input type='text' placeholder='search category' value={searchTemplateCategoryText} onChange={e => setSearchTemplateCategoryText(e.target.value)} />
                </div>

                <div className="category-list">
                  {
                    fetchingTemplateCategories
                      ?
                      <div className="d-flex mt-4 w-100 h-100 gap-2 justify-content-center align-items-center flex-column">
                        <div className="mx-2 spinner-border spinner-border-sm" role="status">
                          <span className="sr-only"></span>
                        </div>
                        <p className="font-xxs">
                          Loading categories
                        </p>
                      </div>
                      :
                      templateCategories.length === 0
                        ?
                        <div className="d-flex mt-4 w-100 h-100 justify-content-center align-items-center flex-column">
                          <BiCategoryAlt className="fs-3" />
                          <p className="font-xxs">
                            Nothing found
                          </p>
                        </div>
                        :
                        <div className="dropdown-categories">
                          {
                            templateCategories.map((category, i) => {
                              return (
                                <div key={i} onClick={() => { selectTemplateCategory(category); setCategoryDropdownVisible(false) }} className={`filter-categories-category tag tag-color-${i % 4 + 1}`}>
                                  {category.category}
                                </div>
                              )
                            })
                          }
                        </div>
                  }
                </div>
              </div>
            </div>
          </div>

        </div>
        {user.role_id === 1
          &&
          <div className='btn-container d-flex flex-row justify-content-end align-items-center gap-2'>
            <NavLink to="/prompt-templates?createTemplate=true" className='button'>New&nbsp;Template</NavLink>
          </div>
        }
      </div>

      <div className="applied-filters">
        {searchingWithText && templateSearchText.length > 0
          &&
          <span className={`tag tag-search editing`}>search: {searchText} <button type='button' title='remove filter' id='remove-tag-button' className='edit-btn remove-tag-button' onClick={e => { setTemplateSearchText(""); setSearchText(""); fetchTemplates() }}><GrFormClose className={`edit-icon`} /></button></span>
        }
        {selectedFilterCategories.length > 0
          &&
          selectedFilterCategories.map((category, i) => {
            return (
              <span className={`tag tag-color-${i % 4 + 1} editing`}>{category.category} <button type='button' title='remove filter' id='remove-tag-button' className='edit-btn remove-tag-button' onClick={e => { removeTemplateCategory(category) }}><GrFormClose className={`edit-icon`} /></button></span>
            )
          })
        }
      </div>

      {loadingTemplates
        ?
        <div>
          <div className='pt-4'>
            <LoadingSkeleton type='template' />
            <LoadingSkeleton type='template' />
            <LoadingSkeleton type='template' />
            <LoadingSkeleton type='template' />
          </div>
        </div>

        :
        <div className='table-container mb-5 pb-5'>
          <table className='templates-table w-100' >
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                {/* <th>Description</th> */}
                <th>Type</th>
                {(selectedUser?.role_id ? selectedUser.role_id === 1 : user.role_id === 1)
                  &&
                  <th>Company</th>
                }
                <th>Categories</th>
                {(selectedUser?.role_id ? selectedUser.role_id !== 1 : user.role_id !== 1)
                  &&
                  <th>Privacy</th>
                }
                <th>Modified</th>
                <th>Action</th>
              </tr>
            </thead>
            {templates.length === 0
              ?
              <tbody>
                <tr>
                  <td colSpan={7}>No templates found</td>
                </tr>
              </tbody>

              :
              <tbody>
                {templates.map((template, i) => {
                  return (
                    <tr key={i} className={`${(selectedTemplate?.template_id === template.template_id && deletingTemplate) ? 'deleting-key' : ''}`}>
                      <td style={{ width: "3rem" }}>{i + 1}</td>
                      <td className='d-flex flex-column gap-2 justify-content-start align-items-start'>
                        {template.name}
                        {template.was_installed_from_marketplace
                          ?
                          <span className='shared-tag' title="This tempate was installed from marketplace"><img src={logo} className='w-xxs h-xxs' /> Marketplace</span>
                          : ''
                        }
                        {template.is_shared
                          ?
                          <span className='shared-tag'>shared with you</span>
                          : ''
                        }
                        {template.is_sharing
                          &&
                          <OverlayTrigger
                            delay={{ hide: 450, show: 300 }}
                            overlay={(props) => (
                              <Tooltip {...props}>
                                This template is shared
                              </Tooltip>
                            )}
                            placement="right"
                          >
                            <button className='button-plain-text'><TbWorldShare className='w-s h-s text-secondary' /></button>
                          </OverlayTrigger>
                        }
                        {template.notify_creator_on_use
                          &&
                          <OverlayTrigger
                            delay={{ hide: 450, show: 300 }}
                            overlay={(props) => (
                              <Tooltip {...props}>
                                When some use this template you will be notified by email
                              </Tooltip>
                            )}
                            placement="right"
                          >
                            <button className='button-transparent'>
                              <MdNotificationsActive className='font-s' />
                            </button>
                          </OverlayTrigger>
                        }
                      </td>
                      {/* <td style={{ minWidth: '8rem' }}>{template.description}</td> */}
                      {selectedUser?.role_id ? selectedUser.role_id === 1 : user.role_id === 1
                        &&
                        <td>
                          {template.selectedCompany !== null
                            ? template.selectedCompany.company_name
                            :
                            <span className='d-flex justify-content-center'>-</span>
                          }
                        </td>
                      }
                      <td>{template.type === "CHAT_STARTER_PRIVATE" ? "Private Chat Starter" : template.type === "CHAT_STARTER_OPEN" ? "Open Chat Starter" : "Normal"}</td>
                      <td>
                        {template.selectedCategories.length === 0
                          ? ' - '
                          :
                          template.selectedCategories.map(category => category.category.toLowerCase()).join(", ")
                        }
                      </td>
                      {(selectedUser?.role_id ? selectedUser.role_id !== 1 : user.role_id !== 1)
                        &&
                        <td>
                          {
                            template.is_public
                              ?
                              <div className='d-flex gap-1 align-items-center'>
                                <MdPublic /><span>Public</span>
                              </div>

                              :
                              <div className='d-flex gap-1 align-items-center'>
                                <RiGitRepositoryPrivateLine /><span>Private</span>
                              </div>
                          }
                          {
                            template?.selectedCompany?.company_id
                            &&
                            <span className='shared-tag mt-2' title={`${template?.selectedCompany?.company_name}`}>Official</span>
                          }
                        </td>
                      }
                      <td>{formatDate(new Date(template.updated_at))}</td>

                      <td className='py-md-1 d-flex align-items-center'>
                        <OverlayTrigger
                          delay={{ hide: 450, show: 300 }}
                          overlay={(props) => (
                            <Tooltip {...props}>
                              Use template in chat
                            </Tooltip>
                          )}
                          placement="right"
                        >
                          <Link to={`/start-chat?templateId=${template.template_id}`} className='edit-btn dropdown-toggle'><RiChatUploadLine className='edit-icon copy-icon' /></Link>
                        </OverlayTrigger>

                        <MyDropdown position='right' disabled={loadingSelectedTemplateStructure}>

                          {/* <div
                            className='dropdown-item'
                            onClick={async (e) => {
                              navigate(`/start-chat?templateId=${template.template_id}`)
                            }}
                          >
                            Use in chat
                          </div> */}

                          <div
                            className='dropdown-item'
                            onClick={async (e) => {
                              setSelectedTemplate(template);
                              await setTemplateStructure(template.template_id, () => {
                                openViewTemplateModal()
                              })
                            }}
                          >
                            Preview
                          </div>

                          {!template.is_shared
                            &&
                            <div
                              className='dropdown-item'
                              onClick={async (e) => {
                                setSelectedTemplate(template);
                                const templateStructureIsSet = await setTemplateStructure(template.template_id, () => {
                                  showEditModal()
                                });
                              }}
                            >
                              Edit
                            </div>
                          }

                          {template.is_public
                            &&
                            <div className='dropdown-item'>
                              <PublicTemplateSearchEngineVisibilityDialog
                                showAsLink={true}
                                template_id={template.template_id}
                                template_name={template.name}
                              />
                            </div>
                          }

                          <div className='dropdown-item'>
                            <ShareTemplateDialog
                              selectedUser={selectedUser}
                              setSelectedUser={setSelectedUser}
                              showAsLink={true}
                              templateDetails={template}
                              onTemplateDetailsChange={data => {
                                setTemplates(prev => {
                                  let updatedData = [...prev]
                                  const indexOfSelectedTemplate = prev.findIndex(template => template.template_id === data.template_id)
                                  updatedData[indexOfSelectedTemplate].is_sharing = data.is_sharing
                                  return updatedData
                                })
                              }}
                            />
                          </div>

                          {!template.is_shared
                            &&
                            <div className='dropdown-item'>
                              <ShareTemplateAccess showingShareTemplateModalAsLink={true} template_id={template.template_id} is_shared={template.is_shared} />
                            </div>
                          }

                          {!template.is_shared
                            &&
                            <div className='dropdown-item' onClick={(e) => { setSelectedTemplate(template); setDuplicateTemplateModalState(true) }}>
                              Duplicate
                            </div>
                          }

                          {!template.is_shared
                            &&
                            <div className='dropdown-item' onClick={(e) => { setSelectedTemplate(template); setDeleteTemplateConfirmationModal(true) }}>
                              Delete
                            </div>
                          }

                        </MyDropdown>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            }
          </table>
        </div>
      }


      {/* Add Template Modal */}
      <Modal show={show} id="template-builder-modal" onHide={handleClose} centered size="xl">
        <Modal.Header closeButton>
          <Modal.Title>
            Create Template {templateData?.typeText && `- ${templateData.typeText}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {templateBuilderStep === 0
            ?
            <div id='template-builder'>
              {!templateData.type
                ?
                <div className='container d-flex flex-column align-items-center justify-content-center'>
                  <div>
                    <h5 className='text-center font-l'>Select a template type to continue</h5>
                    <p className='text-center'>Not sure which template type to select? Check out our guide on <a href="https://sagecollab.com/sagecollab-template-types" target='_blank'>SageCollab Templates</a></p>
                  </div>

                  <div className='d-flex flex-column align-items-center gap-5 mt-5'>
                    <div className='d-flex justify-content-center gap-3 flex-wrap'>
                      <button
                        className='button'
                        onClick={() => setTemplateData(prev => { return { ...prev, type: "CHAT_STARTER_PRIVATE", typeText: "Private Chat Started" } })}
                      >
                        <RiLockLine className='w-s h-s' /> Private Chat Starter
                      </button>

                      <button
                        className='button button-border'
                        onClick={() => setTemplateData(prev => { return { ...prev, type: "CHAT_STARTER_OPEN", typeText: "Open Chat Starter" } })}
                      >
                        <RiGlobalLine className='w-s h-s' /> Open Chat Starter
                      </button>
                    </div>

                    <div className='chat-info'>
                      <span>or</span>
                    </div>

                    <button
                      className='button button-ghost'
                      onClick={() => setTemplateData(prev => { return { ...prev, type: "NORMAL", typeText: "Normal" } })}
                    >
                      <HiOutlineTemplate className='w-s h-s' /> Normal
                    </button>

                  </div>
                </div>

                :
                <>
                  <div className='left-panel'>
                    <h5>Add elements</h5>
                    {renderTemplateElements(templateData)}
                  </div>

                  <div className='right-panel'>
                    {renderSelectedTemplateElements(newTemplateStructure, setNewTemplateStructure, handleNewTemplateItemSort)}
                  </div>
                </>
              }
            </div>

            :
            <div id="template-builder">
              <form className='left-panel p-0' onSubmit={addTemplate}>
                <div className='form-container'>

                  <h5>Add template Details</h5>
                  {renderSelectedTemplateDetailsForm(templateData, setTemplateData, false)}
                </div>

                <div className='sticky-footer d-flex justify-content-between align-items-center' id="sticky-footer">
                  <button type="button" className='button-plain-text d-flex align-items-center justify-content-center gap-2 font-xxs' onClick={() => setTemplateBuilderStep(0)}>
                    <BsArrowLeftShort className='font-s' />
                    Back
                  </button>
                  <button type="submit" className='button d-flex align-items-center justify-content-center gap-2' disabled={addingTemplate}>
                    {addingTemplate
                      ?
                      <div className="mx-2 spinner-border spinner-border-sm" role="status">
                        <span className="sr-only"></span>
                      </div>
                      : "Save"
                    }
                  </button>
                </div>
              </form>

              <div className='right-panel template-elements-container h-100 overflow-scroll'>
                <h5>Preview</h5>
                <div>
                  {renderSelectedTemplatePreview(newTemplateStructure, setNewTemplateStructure)}
                </div>
              </div>
            </div>
          }
        </Modal.Body >
        <Modal.Footer>
          <div className='d-flex flex-column-reverse flex-sm-row w-100  align-items-end align-items-sm-center justify-content-sm-between gap-2'>
            {templateBuilderStep === 0 && templateData.type
              &&
              <>
                <p className='m-0 font-us text-secondary text-end text-sm-start'><strong>Tip:</strong> drag an element up/down to change it's position.</p>
                <button type="button" className='button d-flex align-items-center justify-content-center gap-2' onClick={() => verifyTemplateBuilderStep(0, newTemplateStructure, setNewTemplateStructure, setTemplateBuilderStep)} disabled={verifyingTemplateStep}>
                  {showingPreviewTextOnButton
                    ? "Preview"
                    : "Next"
                  }
                  {verifyingTemplateStep
                    ?
                    <div className="mx-2 spinner-border spinner-border-sm" role="status">
                      <span className="sr-only"></span>
                    </div>
                    :
                    <BsArrowRightShort />
                  }
                </button>
              </>
            }
          </div>
        </Modal.Footer>
      </Modal >


      {/* Edit Template Modal */}
      < Modal show={editModal} id="template-builder-modal" onHide={closeEditModal} centered size="xl" >
        <Modal.Header closeButton>
          <Modal.Title>
            Edit Template
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {templateEditorStep === 0
            ?
            <div id='template-builder'>
              <div className='left-panel'>
                <h5>Add elements</h5>

                {renderTemplateElements(selectedTemplate)}
              </div>

              <div className='right-panel'>
                {renderSelectedTemplateElements(selectedTemplateStructure, setSelectedTemplateStructure, handleSelectedTemplateItemSort)}
              </div>
            </div>

            :
            <div id="template-builder">
              <form className='left-panel p-0' onSubmit={updateTemplate}>
                <div className='form-container'>
                  <h5>Add template Details</h5>

                  {renderSelectedTemplateDetailsForm(selectedTemplate, setSelectedTemplate, true)}
                </div>

                <div className='sticky-footer d-flex justify-content-between align-items-center' id="sticky-footer">
                  <button type="button" className='button-plain-text d-flex align-items-center justify-content-center gap-2 font-xxs' onClick={() => setTemplateEditorStep(0)}>
                    <BsArrowLeftShort className='font-s' />
                    Back
                  </button>
                  {selectedTemplate?.was_installed_from_marketplace === 1
                    &&
                    <button type='button' onClick={() => openConfirmResetModal()} className='button-plain-text gap-1'><BiRefresh style={{ fontSize: 'var(--fs-2)', fontWeight: '400' }} /> Reset to default</button>
                  }
                  <div />
                  <button className="button" disabled={addingTemplate}>
                    {addingTemplate
                      ?
                      <>
                        <div className="mx-2 spinner-border spinner-border-sm" role="status">
                          <span className="sr-only"></span>
                        </div>
                      </>

                      : 'Update'
                    }
                  </button>
                </div>
              </form>

              <div className='right-panel template-elements-container h-100 overflow-scroll'>
                <h5>Preview</h5>
                <div>
                  {renderSelectedTemplatePreview(selectedTemplateStructure, setSelectedTemplateStructure)}
                </div>
              </div>
            </div>
          }
        </Modal.Body >
        <Modal.Footer>
          <div className='d-flex flex-column-reverse flex-sm-row w-100  align-items-end align-items-sm-center justify-content-sm-between gap-2'>
            <p className='m-0 font-us text-secondary text-end text-sm-start'><strong>Tip:</strong> drag an element up/down to change it's position.</p>
            {templateEditorStep === 0
              &&
              <>
                <span />
                <button
                  type="button"
                  className='button d-flex align-items-center justify-content-center gap-2'
                  onClick={() => {
                    verifyTemplateBuilderStep(0, selectedTemplateStructure, setSelectedTemplateStructure, setTemplateEditorStep)
                  }}
                  disabled={verifyingTemplateStep}
                >
                  Next
                  {verifyingTemplateStep
                    ?
                    <div className="mx-2 spinner-border spinner-border-sm" role="status">
                      <span className="sr-only"></span>
                    </div>
                    :
                    <BsArrowRightShort />
                  }
                </button>
              </>
            }
          </div>
        </Modal.Footer>
      </Modal >



      {/* View Template Modal */}
      < Modal show={viewTemplateModal} onHide={closeViewTemplateModal} centered size="md" >
        <Modal.Header closeButton>
          <Modal.Title>
            Preview
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='h-100 overflow-scroll'>
            <h5>{selectedTemplate?.name}</h5>
            <p>{selectedTemplate?.description}</p>
            <div className=''>
              {renderSelectedTemplatePreview(selectedTemplateStructure, setSelectedTemplateStructure, true)}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='d-flex justify-content-end'>
            <button className='button' onClick={() => navigate(`/start-chat?templateId=${selectedTemplate?.template_id}`)}>Use in chat</button>
          </div>
        </Modal.Footer>
      </Modal >



      {/* Confirm reset Modal */}
      < Modal show={confirmResetModal} onHide={closeConfirmResetModal} centered size="md" >
        <Modal.Header closeButton>
          <Modal.Title>
            Confirm Reset
          </Modal.Title>
        </Modal.Header>
        <form onSubmit={resetTemplateToDefaultMarketplaceValues}>
          <Modal.Body>
            <p className='my-3 px-3'>
              Resetting this template to its default settings will revert it to the original marketplace configuration. Any custom changes you've made to the template will be lost and cannot be recovered. Are you sure you want to proceed with the reset?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <button className='button button-danger' type='submit' disabled={resettingTemplate}>
              {resettingTemplate
                ?
                <>
                  Yes, reset
                  <div className="mx-2 spinner-border spinner-border-sm" role="status">
                    <span className="sr-only"></span>
                  </div>
                </>
                : 'Yes, reset'
              }
            </button>
          </Modal.Footer>
        </form>
      </Modal >



      {/* Duplicate template Modal */}
      < Modal show={duplicatateTemplateModalState} onHide={() => setDuplicateTemplateModalState(false)} centered size="md" >
        <Modal.Header closeButton>
          <Modal.Title>
            Duplicate Template
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='container'>
            <p className='my-3'>
              Do you want to duplicate this template?
            </p>
            <p className='m-0'>{selectedTemplate?.name}</p>
            <p className='text-secondary'>{selectedTemplate?.description}</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='button' disabled={duplicatingTemplate} onClick={duplicateTemplate}>
            Yes, duplicate
            {duplicatingTemplate
              &&
              <div className="mx-2 spinner-border spinner-border-sm" role="status">
                <span className="sr-only"></span>
              </div>
            }
          </button>
        </Modal.Footer>
      </Modal >


      {/* Delete template confirmation Modal */}
      < Modal show={deleteTemplateConfirmationModal} onHide={() => setDeleteTemplateConfirmationModal(false)} centered size="md" >
        <Modal.Header closeButton>
          <Modal.Title>
            Confirm Delete
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='container'>
            <p className='my-3'>
              Are you sure you want to delete this template?
            </p>
            <p className='m-0'>{selectedTemplate?.name}</p>
            <p className='text-secondary'>{selectedTemplate?.description}</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='button button-danger' disabled={deletingTemplate} onClick={() => deleteTemplate(selectedTemplate?.template_id)}>
            Yes, delete
            {deletingTemplate
              &&
              <div className="mx-2 spinner-border spinner-border-sm" role="status">
                <span className="sr-only"></span>
              </div>
            }
          </button>
        </Modal.Footer>
      </Modal >


    </div >
  )
}

export default MyTemplates