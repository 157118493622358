import React, { useEffect, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import TemplateMarketplace from './TemplateMarketplace'
import MyTemplates from './MyTemplates'
import SharedTemplates from './SharedTemplates'
import { Helmet } from 'react-helmet'

const Templates = () => {
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const activeTab = parseInt(queryParams.get('activeTab')) || 0
    const activeTemplateTab = parseInt(queryParams.get('activeTemplateTab')) || 0

    const [activeElement, setActiveElement] = useState(<TemplateMarketplace />)
    const navigate = useNavigate()

    useEffect(() => {
        if (activeTemplateTab === 0) {
            setActiveElement(<TemplateMarketplace showHeader={false} />)
        }
        else if (activeTemplateTab === 1) {
            setActiveElement(<MyTemplates />)
        }
        else if (activeTemplateTab === 2) {
            setActiveElement(<SharedTemplates />)
        }
    }, [activeTemplateTab])


    return (
        <>
            <div className='container py-5'>
                <Helmet>
                    <title>Templates - SageCollab</title>
                </Helmet>
                <div className='flexed-between align-items-start mb-2 gap-2'>
                    <h2 className='text-left w-fit title m-0'>Templates</h2>
                    <div className='d-flex align-items-center gap-2 justify-content-end flex-wrap'>
                        <NavLink to="/start-chat" className='button button-new-chat'>New&nbsp;Chat</NavLink>
                        <NavLink to="/?activeTab=4&activeTemplateTab=1&createTemplate=true" className='button shine-hover'>New&nbsp;Template</NavLink>
                    </div>
                </div>

            </div>
            <div className='template-tabs-container'>
                <div className='template-tabs container'>
                    <div className={`template-tab ${activeTemplateTab === 0 ? "active" : ""}`} onClick={() => navigate(`/?activeTab=${activeTab}&activeTemplateTab=0`)}>Marketplace</div>
                    <div className={`template-tab ${activeTemplateTab === 1 ? "active" : ""}`} onClick={() => navigate(`/?activeTab=${activeTab}&activeTemplateTab=1`)}>My Templates</div>
                    <div className={`template-tab ${activeTemplateTab === 2 ? "active" : ""}`} onClick={() => navigate(`/?activeTab=${activeTab}&activeTemplateTab=2`)}>Shared</div>
                </div>
                <span />
            </div>
            <div className='render-area container'>
                {activeElement}
            </div>
        </>
    )
}

export default Templates